import { VFC } from 'react';
import { ProductDetailsPage } from '../../../../../lib/api/models/umbraco';
import { PdpVanFinanceSetup } from '../../../../../lib/api/models/umbraco/product-details';
import { VanLeasingOwnershipFinancialTab, VanLeasingOwnershipOperationalTab } from '../../../../../lib/api/models/umbraco/product-types/vans.types';
import { hesselViewModels } from '../../../../../lib/view-models';
import { formatDistance } from '../../../../../utils/helpers/distance.helper';
import { TabbedCard } from '../../../../shared/tabbed-card/tabbed-card.component';
import { OwnershipCard } from '../../../ownership-card/ownership-card.component';
import { OpenDialog, usePdpModals } from '../../../product-details-page/product-details-page.context';
import { useVanCtaConfig } from '../../use-van-cta-config';
import {
    IncludingDeliveryAndComplianceTextWrapper,
    PopularityBadge,
    PopularityBadgeText,
    PositionedComplianceText,
    StyledIncludingDeliveryText,
} from './van-leasing-tabs.styled';
import { PriceByOwnership } from '../../../hooks/price-by-ownership.types';
import {
    getLeasingTermsUsps,
    getVanFinancialLeasingDisclaimer,
    getVanOperationalLeasingDisclaimer,
} from '../../../../../utils/helpers/price-by-ownership.helper';
import { ProductDetailsStore } from '../../../../../lib/state/hessel-site/product-details';
import { Abbreviations } from '../../../../../constants/units-and-abbreviations';
import { LeasingTerms } from '../../../../../lib/api/models/hessel-api/vehicle';

export type VanLeasingTabsProps = {
    page: ProductDetailsPage;
    product: hesselViewModels.ProductDetails;
    selectedOwnershipTab: hesselViewModels.OwnershipTab;
    setSelectedOwnershipTab: (tab: hesselViewModels.OwnershipTab) => void;
    vanFinancialSetup: Array<PdpVanFinanceSetup>;
    priceVisibilityChanged: (visible: boolean) => void;
    priceInformation: PriceByOwnership;
};

export const VanLeasingTabs: VFC<VanLeasingTabsProps> = ({
    page,
    product,
    selectedOwnershipTab,
    setSelectedOwnershipTab,
    vanFinancialSetup,
    priceVisibilityChanged,
    priceInformation,
}) => {
    const { openDialog } = usePdpModals();
    const CtaConfig = useVanCtaConfig(page);

    const { isAFromPrice } = ProductDetailsStore.useStoreState((state) => state);

    if (product.vehicleType !== 'Van') {
        return null;
    }

    const operationalLeasingCms = vanFinancialSetup.find((x) => x.alias === 'vanLeasingOwnershipOperationalTab') as VanLeasingOwnershipOperationalTab;
    const operationalLeasingTab =
        operationalLeasingCms && product.purchaseTypes.operationalLeasing.show
            ? {
                  id: 'Van Operationel Leasing',
                  label: operationalLeasingCms?.tabName ?? '',
                  description: `${product.purchaseTypes.operationalLeasing.durationMonths} mdr.`,
                  badge:
                      product.brand === 'Mercedes-Benz' ? (
                          <PopularityBadge>
                              <PopularityBadgeText>Populær</PopularityBadgeText>
                          </PopularityBadge>
                      ) : null,
                  onClick: () => setSelectedOwnershipTab('Van Operationel Leasing'),
                  content: (
                      <OwnershipCard
                          priceVisibilityChanged={priceVisibilityChanged}
                          primaryCta={{
                              label: CtaConfig[product.availability]?.labels.primary,
                              action: CtaConfig[product.availability]?.actions.primary,
                          }}
                          secondaryCta={{
                              label: CtaConfig[product.availability]?.labels.secondary,
                              action: CtaConfig[product.availability]?.actions.secondary,
                          }}
                          footerCta={{
                              label: CtaConfig[product.availability]?.labels.footerLink,
                              action: CtaConfig[product.availability]?.actions.footerLink,
                          }}
                          usps={[
                              ...(product.purchaseTypes.operationalLeasing.kilometersPerYear
                                  ? [
                                        {
                                            label: `${formatDistance(
                                                (product.purchaseTypes.operationalLeasing.kilometersPerYear /
                                                    product.purchaseTypes.operationalLeasing.durationMonths) *
                                                    12
                                            )} ${Abbreviations.KM_SLASH_AAR}.`,
                                            icon: operationalLeasingCms.mileageIcon,
                                            isEmphasized: true,
                                        },
                                    ]
                                  : []),
                              ...getLeasingTermsUsps(
                                  product.purchaseTypes.operationalLeasing.leasingTerms as LeasingTerms,
                                  operationalLeasingCms.mileageIcon
                              ),
                              ...(operationalLeasingCms?.usps ?? []),
                          ]}
                          linkCta={
                              product.purchaseTypes.financialLeasing.show && product.purchaseTypes.operationalLeasing.show
                                  ? {
                                        label: operationalLeasingCms?.ctaText,
                                        action: () => openDialog({ dialog: OpenDialog.CompareVansLeasingTypes }),
                                    }
                                  : undefined
                          }
                          price={priceInformation}
                          disclaimer={
                              <IncludingDeliveryAndComplianceTextWrapper>
                                  {product.availability !== 'Engros' && page.vanIncludingDeliveryText?.length ? (
                                      <StyledIncludingDeliveryText>{page.vanIncludingDeliveryText}</StyledIncludingDeliveryText>
                                  ) : null}

                                  {product.availability === 'Engros' && page.vanIncludingDeliveryTextEngros?.length > 0 ? (
                                      <StyledIncludingDeliveryText>{page.vanIncludingDeliveryTextEngros}</StyledIncludingDeliveryText>
                                  ) : null}

                                  <PositionedComplianceText
                                      text={getVanOperationalLeasingDisclaimer(
                                          product.specifications.consumption,
                                          product.specifications.co2Emission,
                                          product.energyRating,
                                          priceInformation.price,
                                          product.purchaseTypes.operationalLeasing.downPayment,
                                          product.purchaseTypes.operationalLeasing.durationMonths,
                                          product.purchaseTypes.operationalLeasing.kilometersPerYear,
                                          product.purchaseTypes.operationalLeasing.establishmentFee ?? 0,
                                          product.purchaseTypes.pantOwnerDeclaration,
                                          +product.specifications.greenOwnershipFee,
                                          isAFromPrice,
                                          product.purchaseTypes.operationalLeasing.leasingTerms as LeasingTerms
                                      )}
                                  />
                              </IncludingDeliveryAndComplianceTextWrapper>
                          }
                      />
                  ),
              }
            : null;

    const financialLeasingCms = vanFinancialSetup.find((x) => x.alias === 'vanLeasingOwnershipFinancialTab') as VanLeasingOwnershipFinancialTab;
    const financialLeasingTab =
        financialLeasingCms && product.purchaseTypes.financialLeasing.show
            ? {
                  id: 'Van Finansiel Leasing',
                  label: financialLeasingCms?.tabName ?? '',
                  description: `${product.purchaseTypes.financialLeasing.durationMonths} mdr.`,
                  badge:
                      product.brand === 'Renault' || product.brand === 'Ford' || product.brand === 'Dacia' ? (
                          <PopularityBadge>
                              <PopularityBadgeText>Populær</PopularityBadgeText>
                          </PopularityBadge>
                      ) : null,
                  onClick: () => setSelectedOwnershipTab('Van Finansiel Leasing'),
                  content: (
                      <OwnershipCard
                          priceVisibilityChanged={priceVisibilityChanged}
                          primaryCta={{
                              label: CtaConfig[product.availability]?.labels.primary,
                              action: CtaConfig[product.availability]?.actions.primary,
                          }}
                          secondaryCta={{
                              label: CtaConfig[product.availability]?.labels.secondary,
                              action: CtaConfig[product.availability]?.actions.secondary,
                          }}
                          footerCta={{
                              label: CtaConfig[product.availability]?.labels.footerLink,
                              action: CtaConfig[product.availability]?.actions.footerLink,
                          }}
                          usps={[
                              ...(product.purchaseTypes.financialLeasing.kilometersPerYear
                                  ? [
                                        {
                                            label: `${formatDistance(
                                                (product.purchaseTypes.financialLeasing.kilometersPerYear /
                                                    product.purchaseTypes.financialLeasing.durationMonths) *
                                                    12
                                            )} ${Abbreviations.KM_SLASH_AAR}.`,
                                            icon: financialLeasingCms.mileageIcon,
                                            isEmphasized: true,
                                        },
                                    ]
                                  : []),
                              ...getLeasingTermsUsps(
                                  product.purchaseTypes.financialLeasing.leasingTerms as LeasingTerms,
                                  financialLeasingCms.mileageIcon
                              ),
                              ...(financialLeasingCms?.usps ?? []),
                          ].filter((_, idx) => idx < 4)}
                          linkCta={
                              product.purchaseTypes.financialLeasing.show && product.purchaseTypes.operationalLeasing.show
                                  ? { label: financialLeasingCms?.ctaText, action: () => openDialog({ dialog: OpenDialog.CompareVansLeasingTypes }) }
                                  : undefined
                          }
                          price={priceInformation}
                          disclaimer={
                              <IncludingDeliveryAndComplianceTextWrapper>
                                  {product.availability !== 'Engros' && page.vanIncludingDeliveryText?.length > 0 ? (
                                      <StyledIncludingDeliveryText>{page.vanIncludingDeliveryText}</StyledIncludingDeliveryText>
                                  ) : null}

                                  {product.availability === 'Engros' && page.vanIncludingDeliveryTextEngros?.length > 0 ? (
                                      <StyledIncludingDeliveryText>{page.vanIncludingDeliveryTextEngros}</StyledIncludingDeliveryText>
                                  ) : null}

                                  <PositionedComplianceText
                                      text={getVanFinancialLeasingDisclaimer(
                                          product.specifications.consumption,
                                          product.specifications.co2Emission,
                                          product.energyRating,
                                          priceInformation.price,
                                          product.purchaseTypes.financialLeasing.downPayment,
                                          product.purchaseTypes.financialLeasing.durationMonths,
                                          product.purchaseTypes.financialLeasing.kilometersPerYear,
                                          +product.specifications.greenOwnershipFee,
                                          product.purchaseTypes.financialLeasing.residualValue,
                                          isAFromPrice,
                                          product.purchaseTypes.financialLeasing.leasingTerms as LeasingTerms
                                      )}
                                  />
                              </IncludingDeliveryAndComplianceTextWrapper>
                          }
                      />
                  ),
              }
            : null;

    const leasingOwnershipTabs = [];
    if (operationalLeasingTab) {
        leasingOwnershipTabs.push(operationalLeasingTab);
    }
    if (financialLeasingTab) {
        leasingOwnershipTabs.push(financialLeasingTab);
    }

    const tabs = leasingOwnershipTabs;

    return (
        <TabbedCard
            controlled
            activeTabId={selectedOwnershipTab}
            onAccesibilityTabSelected={(id) => setSelectedOwnershipTab(id as hesselViewModels.OwnershipTab)}
            tabs={tabs}
        />
    );
};
