import { VFC } from 'react';
import { hesselViewModels } from '../../../../lib/view-models';
import { getVehicleAvailabilityText } from '../../../../utils/helpers';
import { Separator } from '../../../shared';
import { CarSummary } from './car-summary';
import { BorderBox, HeaderWrapper, Header, Badge, BadgeContent, SectionWrapper, TextGroup, Label, StyledValue } from './order-summary-desktop.styled';
import { TotalNoMoms } from './total-no-moms';
import { TotalPrice } from './total-price';

export const OrderSummaryDesktop: VFC<
    hesselViewModels.OrderSummary & { showRemoveButton?: boolean; clearMiniBasket?: () => void; showBusinessSection: boolean }
> = ({
    imageUrl,
    model,
    leasingPeriod,
    carPrice,
    colorName,
    colorImageUrl,
    extraEquipmentPrice,
    optionalEquipmentPrice,
    totalPriceIncludingMoms,
    totalPriceExcludingMoms,
    showRemoveButton,
    clearMiniBasket,
    startupDate,
    availability,
    brandTitle,
    showBusinessSection,
    establishmentFee,
}) => {
    return (
        <BorderBox>
            <HeaderWrapper>
                <Header>Din bestilling</Header>
                <Badge>
                    <BadgeContent>{getVehicleAvailabilityText(availability)}</BadgeContent>
                </Badge>
            </HeaderWrapper>

            <Separator marginBottom="0px" marginTop="px" />

            <CarSummary
                imageUrl={imageUrl}
                brand={brandTitle ?? ''}
                model={model}
                colorName={colorName}
                colorUrl={colorImageUrl}
                showRemove={showRemoveButton}
                onRemoveButtonClick={clearMiniBasket}
            />

            <Separator marginBottom="0px" marginTop="px" />

            <SectionWrapper>
                <TextGroup>
                    <Label>Leasingperiode {startupDate ? `(Opstart fra ${startupDate.toLocaleDateString('da-DK')})` : null}</Label>
                    <StyledValue>{leasingPeriod}</StyledValue>
                </TextGroup>
            </SectionWrapper>

            <Separator marginBottom="0px" marginTop="px" />

            <SectionWrapper>
                <TextGroup>
                    <Label>Bilens pris</Label>
                    <StyledValue>{carPrice}</StyledValue>
                </TextGroup>
                <TextGroup>
                    <Label>Ekstraudstyr</Label>
                    <StyledValue>{extraEquipmentPrice}</StyledValue>
                </TextGroup>
                <TextGroup>
                    <Label>Tilvalg</Label>
                    <StyledValue>{optionalEquipmentPrice}</StyledValue>
                </TextGroup>
            </SectionWrapper>

            <Separator marginBottom="0px" marginTop="px" />

            <TotalPrice totalPrice={totalPriceIncludingMoms} establishmentFee={establishmentFee} />

            {showBusinessSection ? (
                <>
                    <Separator marginBottom="0px" marginTop="px" />
                    <TotalNoMoms totalPriceNoMoms={totalPriceExcludingMoms} />
                </>
            ) : null}
        </BorderBox>
    );
};
