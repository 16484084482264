import styled, { css } from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledListWrapper = styled.aside<{ hide: boolean }>`
    background-color: #f2f2f2;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
    border: 4px solid white;
    overflow: auto;
    max-height: 600px;
    position: absolute;
    left: 15px;
    right: 15px;
    z-index: 2;
    border-radius: 8px;
    ${(props) =>
        props.hide &&
        css`
            display: none;
        `}

    @media ${device.laptop} {
        position: static;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const StyledWorkshopWrapper = styled.div`
    display: flex;
    gap: 24px;
    align-items: flex-start;
    padding: 24px;
    border-bottom: 1px solid #cccccc;
`;

export const StyledPinIcon = styled.img`
    display: none;
    @media ${device.laptop} {
        display: block;
    }
`;
