import { action } from 'easy-peasy';
import { OwnershipTabArray } from '../../../view-models/vehicle';
import { handleCarResources } from './car-resources.helper';
import { getAvailableConfigurations } from './configuration.helper';
import { ProductDetailsActions } from './product-details.types';
import { getDefaultSelectedTab } from './selected-tab.helper';
import { applyCommonStateUpdates, matchBattery, matchEquipmentLine } from './state-update.helper';
import { evaluateNewColor } from '../../../../utils/helpers';

const default_equipment_list = {
    'Car HiRE': [],
    'Car Privat Leasing': [],
    'Car Finansiering': [],
    'Car Kontant': [],

    'Van Operationel Leasing': [],
    'Van Finansiel Leasing': [],
    'Van Finansiering': [],
    'Van Kontant': [],

    'No Value': [],
};

export const productDetailsActions = (): ProductDetailsActions => ({
    setColor: action((state, payload) => {
        state.selectedColor = payload;

        // When user changes color, Equipments selection needs to be reserved
        const standardEqPackages = state.selectedProduct?.standardEquipmentPackages;
        const extraEqPackages = state.selectedProduct?.extraEquipmentPackages;
        const standardEq = state.selectedProduct?.standardEquipment;
        const extraEq = state.selectedProduct?.extraEquipment;
        const optionalEq = state.selectedProduct?.optionalEquipment;

        state.selectedProduct = state.carFamily.find(
            (x) =>
                x.carType === state.selectedCarType &&
                x.fuelType === state.selectedFuelType &&
                x.transmissionType === state.selectedTransmissionType &&
                x.tractionWheels === state.selectedTractionWheels &&
                (x.vehicleType === 'Van' ? x.lengthType === state.selectedLengthType && x.heightType === state.selectedHeightType : true) &&
                (x.brand !== 'Mercedes-Benz' ? matchEquipmentLine(x.equipmentLine, state.selectedEquipmentLine) : true) &&
                (x.fuelType === 'El'
                    ? matchBattery(
                          x.batteryCapacity ?? '',
                          x.range ?? 0,
                          state.selectedBatteryInformation?.capacity ?? '',
                          state.selectedBatteryInformation?.range ?? 0
                      )
                    : true) &&
                x.colors.some((y) => y.id === state.selectedColor?.id)
        );

        if (!state.selectedProduct) {
            return;
        }

        state.selectedProduct.standardEquipmentPackages = standardEqPackages ?? default_equipment_list;
        state.selectedProduct.extraEquipmentPackages = extraEqPackages ?? default_equipment_list;
        state.selectedProduct.standardEquipment = standardEq ?? default_equipment_list;
        state.selectedProduct.extraEquipment = extraEq ?? default_equipment_list;
        state.selectedProduct.optionalEquipment = optionalEq ?? default_equipment_list;

        state.availableConfiguration = getAvailableConfigurations(state);

        if (state.selectedProduct) {
            const { resources, matchFound } = handleCarResources(state.selectedProduct, state.selectedColor, state.matchedResources);
            state.matchedResources = resources;
            state.colorAndResourceMatched = matchFound;
        }

        if (!state.priceDifferenceAcknowledged) {
            state.priceDifferenceAcknowledged = true;
        }

        state.productUpdateFinished = true;
    }),
    setCarType: action((state, payload) => {
        state.selectedCarType = payload;
        const newSelection = state.carFamily.find(
            (x) =>
                x.carType === payload &&
                x.fuelType === state.selectedFuelType &&
                x.transmissionType === state.selectedTransmissionType &&
                x.tractionWheels === state.selectedTractionWheels &&
                (x.vehicleType === 'Van' ? x.lengthType === state.selectedLengthType && x.heightType === state.selectedHeightType : true) &&
                (x.brand !== 'Mercedes-Benz' ? matchEquipmentLine(x.equipmentLine, state.selectedEquipmentLine) : true) &&
                (x.fuelType === 'El'
                    ? matchBattery(
                          x.batteryCapacity ?? '',
                          x.range ?? 0,
                          state.selectedBatteryInformation?.capacity ?? '',
                          state.selectedBatteryInformation?.range ?? 0
                      )
                    : true)
        );

        state.selectedProduct = newSelection;

        const newColor = evaluateNewColor(newSelection, state.selectedColor, state.selectedTab);

        if (newColor) {
            state.selectedColor = newColor;
        }

        applyCommonStateUpdates(state);
    }),
    setFuelType: action((state, payload) => {
        state.selectedFuelType = payload;
        const newSelection = state.carFamily.find(
            (x) =>
                x.fuelType === payload &&
                x.carType === state.selectedCarType &&
                x.transmissionType === state.selectedTransmissionType &&
                x.tractionWheels === state.selectedTractionWheels &&
                (x.vehicleType === 'Van' ? x.lengthType === state.selectedLengthType && x.heightType === state.selectedHeightType : true) &&
                (x.brand !== 'Mercedes-Benz' ? matchEquipmentLine(x.equipmentLine, state.selectedEquipmentLine) : true) &&
                (x.fuelType === 'El'
                    ? matchBattery(
                          x.batteryCapacity ?? '',
                          x.range ?? 0,
                          state.selectedBatteryInformation?.capacity ?? '',
                          state.selectedBatteryInformation?.range ?? 0
                      )
                    : true)
        );

        state.selectedProduct = newSelection;

        const newColor = evaluateNewColor(newSelection, state.selectedColor, state.selectedTab);

        if (newColor) {
            state.selectedColor = newColor;
        }

        applyCommonStateUpdates(state);
    }),
    setTransmissionType: action((state, payload) => {
        state.selectedTransmissionType = payload;
        const newSelection = state.carFamily.find(
            (x) =>
                x.transmissionType === payload &&
                x.carType === state.selectedCarType &&
                x.fuelType === state.selectedFuelType &&
                x.tractionWheels === state.selectedTractionWheels &&
                (x.vehicleType === 'Van' ? x.lengthType === state.selectedLengthType && x.heightType === state.selectedHeightType : true) &&
                (x.brand !== 'Mercedes-Benz' ? matchEquipmentLine(x.equipmentLine, state.selectedEquipmentLine) : true) &&
                (x.fuelType === 'El'
                    ? matchBattery(
                          x.batteryCapacity ?? '',
                          x.range ?? 0,
                          state.selectedBatteryInformation?.capacity ?? '',
                          state.selectedBatteryInformation?.range ?? 0
                      )
                    : true)
        );

        state.selectedProduct = newSelection;

        const newColor = evaluateNewColor(newSelection, state.selectedColor, state.selectedTab);

        if (newColor) {
            state.selectedColor = newColor;
        }

        applyCommonStateUpdates(state);
    }),
    setTractionWheels: action((state, payload) => {
        state.selectedTractionWheels = payload;
        const newSelection = state.carFamily.find(
            (x) =>
                x.tractionWheels === payload &&
                x.carType === state.selectedCarType &&
                x.fuelType === state.selectedFuelType &&
                x.transmissionType === state.selectedTransmissionType &&
                (x.vehicleType === 'Van' ? x.lengthType === state.selectedLengthType && x.heightType === state.selectedHeightType : true) &&
                (x.brand !== 'Mercedes-Benz' ? matchEquipmentLine(x.equipmentLine, state.selectedEquipmentLine) : true) &&
                (x.fuelType === 'El'
                    ? matchBattery(
                          x.batteryCapacity ?? '',
                          x.range ?? 0,
                          state.selectedBatteryInformation?.capacity ?? '',
                          state.selectedBatteryInformation?.range ?? 0
                      )
                    : true)
        );

        state.selectedProduct = newSelection;

        const newColor = evaluateNewColor(newSelection, state.selectedColor, state.selectedTab);

        if (newColor) {
            state.selectedColor = newColor;
        }

        applyCommonStateUpdates(state);
    }),
    setBatteryInformation: action((state, payload) => {
        state.selectedBatteryInformation = payload;
        const newSelection = state.carFamily.find(
            (x) =>
                x.batteryCapacity === payload.capacity &&
                x.range === payload.range &&
                x.carType === state.selectedCarType &&
                x.fuelType === state.selectedFuelType &&
                (x.vehicleType === 'Van' ? x.lengthType === state.selectedLengthType && x.heightType === state.selectedHeightType : true) &&
                (x.brand !== 'Mercedes-Benz' ? matchEquipmentLine(x.equipmentLine, state.selectedEquipmentLine) : true) &&
                x.transmissionType === state.selectedTransmissionType &&
                x.tractionWheels === state.selectedTractionWheels
        );

        state.selectedProduct = newSelection;

        const newColor = evaluateNewColor(newSelection, state.selectedColor, state.selectedTab);

        if (newColor) {
            state.selectedColor = newColor;
        }

        applyCommonStateUpdates(state);
    }),
    setLengthType: action((state, payload) => {
        state.selectedLengthType = payload;
        const newSelection = state.carFamily.find(
            (x) =>
                x.lengthType === payload &&
                x.carType === state.selectedCarType &&
                x.fuelType === state.selectedFuelType &&
                x.transmissionType === state.selectedTransmissionType &&
                x.heightType === state.selectedHeightType &&
                (x.brand !== 'Mercedes-Benz' ? matchEquipmentLine(x.equipmentLine, state.selectedEquipmentLine) : true) &&
                (x.fuelType === 'El'
                    ? matchBattery(
                          x.batteryCapacity ?? '',
                          x.range ?? 0,
                          state.selectedBatteryInformation?.capacity ?? '',
                          state.selectedBatteryInformation?.range ?? 0
                      )
                    : true) &&
                x.tractionWheels === state.selectedTractionWheels
        );

        state.selectedProduct = newSelection;

        const newColor = evaluateNewColor(newSelection, state.selectedColor, state.selectedTab);

        if (newColor) {
            state.selectedColor = newColor;
        }

        applyCommonStateUpdates(state);
    }),
    setHeightType: action((state, payload) => {
        state.selectedHeightType = payload;
        const newSelection = state.carFamily.find(
            (x) =>
                x.heightType === payload &&
                x.carType === state.selectedCarType &&
                x.fuelType === state.selectedFuelType &&
                x.lengthType === state.selectedLengthType &&
                x.transmissionType === state.selectedTransmissionType &&
                (x.brand !== 'Mercedes-Benz' ? matchEquipmentLine(x.equipmentLine, state.selectedEquipmentLine) : true) &&
                (x.fuelType === 'El'
                    ? matchBattery(
                          x.batteryCapacity ?? '',
                          x.range ?? 0,
                          state.selectedBatteryInformation?.capacity ?? '',
                          state.selectedBatteryInformation?.range ?? 0
                      )
                    : true) &&
                x.tractionWheels === state.selectedTractionWheels
        );

        state.selectedProduct = newSelection;

        const newColor = evaluateNewColor(newSelection, state.selectedColor, state.selectedTab);

        if (newColor) {
            state.selectedColor = newColor;
        }

        applyCommonStateUpdates(state);
    }),

    setEquipmentLine: action((state, payload) => {
        state.selectedEquipmentLine = payload;
        const newSelection = state.carFamily.find(
            (x) =>
                x.equipmentLine === payload &&
                x.carType === state.selectedCarType &&
                x.fuelType === state.selectedFuelType &&
                x.transmissionType === state.selectedTransmissionType &&
                x.tractionWheels === state.selectedTractionWheels &&
                (x.vehicleType === 'Van' ? x.lengthType === state.selectedLengthType && x.heightType === state.selectedHeightType : true) &&
                (x.fuelType === 'El'
                    ? matchBattery(
                          x.batteryCapacity ?? '',
                          x.range ?? 0,
                          state.selectedBatteryInformation?.capacity ?? '',
                          state.selectedBatteryInformation?.range ?? 0
                      )
                    : true)
        );
        state.selectedProduct = newSelection;

        const newColor = evaluateNewColor(newSelection, state.selectedColor, state.selectedTab);

        if (newColor) {
            state.selectedColor = newColor;
        }

        applyCommonStateUpdates(state);
    }),

    updateProductDetails: action((state, payload) => {
        state.selectedProduct = payload;
    }),
    updatePhyronData: action((state, payload) => {
        if (state.selectedProduct) state.selectedProduct.phyronData = payload;
        const { resources, matchFound } = state.selectedProduct
            ? handleCarResources(state.selectedProduct, state.selectedColor)
            : { resources: undefined, matchFound: false };

        state.matchedResources = resources ?? [];
        state.colorAndResourceMatched = matchFound;
    }),
    setPriceDifferenceAcknowledged: action((state, payload) => {
        state.priceDifferenceAcknowledged = payload;
    }),
    resetProductDetailsStore: action((state) => {
        state.carFamily = [];
        state.selectedProduct = undefined;
        state.selectedCarType = undefined;
        state.selectedFuelType = undefined;
        state.selectedTransmissionType = undefined;
        state.selectedTractionWheels = undefined;
        state.selectedBatteryInformation = undefined;
        state.selectedColor = undefined;
        state.allConfigurations = {
            carTypes: [],
            fuelTypes: [],
            transmissionTypes: [],
            tractionWheels: [],
            lengthTypes: [],
            heightTypes: [],
            equipmentLines: [],
            batteryInfoList: [],
        };
        state.availableConfiguration = {
            carTypes: [],
            fuelTypes: [],
            transmissionTypes: [],
            tractionWheels: [],
            lengthTypes: [],
            heightTypes: [],
            equipmentLines: [],
            batteryInfoList: [],
        };
        state.colorAndResourceMatched = false;
        state.selectedLeasingPeriod = undefined;
        state.priceDifferenceAcknowledged = false;
        state.dealershipLocationLabel = undefined;
    }),
    fillStoreFromMiniBasket: action((state, payload) => {
        state.carFamily = payload.carFamily;
        state.selectedProduct = payload.selectedProduct;
        state.selectedCarType = payload.selectedCarType;
        state.selectedFuelType = payload.selectedFuelType;
        state.selectedTransmissionType = payload.selectedTransmissionType;
        state.selectedTractionWheels = payload.selectedTractionWheels;
        state.selectedBatteryInformation = payload.selectedBatteryInformation;
        state.selectedColor = payload.selectedColor;
        state.allConfigurations = payload.allConfigurations;
        state.availableConfiguration = payload.availableConfiguration;
        state.selectedLeasingPeriod = payload.selectedLeasingPeriod;
        state.selectedTab = payload.selectedTab;
        state.ownershipMode = payload.ownershipMode;

        state.priceDifferenceAcknowledged = false;

        // Check for matching image/resource
        if (state.selectedProduct) {
            const { resources, matchFound } = handleCarResources(state.selectedProduct, state.selectedColor);
            state.matchedResources = resources;
            state.colorAndResourceMatched = matchFound;
        }
    }),

    updateStandardEquipmentPackage: action((state, payload) => {
        const equipmentPackage = state.selectedProduct?.standardEquipmentPackages[state.selectedTab].find((x) => x.id === payload.id);
        if (equipmentPackage) {
            equipmentPackage.isSelected = payload.isSelected;
        }

        // Iterate over all tabs and update the same package, this way if an equipment package is selected in one tab, it will be selected in all tabs
        OwnershipTabArray.forEach((tab) => {
            if (tab === state.selectedTab) {
                return;
            }

            const standardEquipmentPackageOnOtherTab = state.selectedProduct?.standardEquipmentPackages[tab].find((x) => x.id === payload.id);
            if (standardEquipmentPackageOnOtherTab) {
                standardEquipmentPackageOnOtherTab.isSelected = payload.isSelected;
            }
        });

        if (!state.priceDifferenceAcknowledged) {
            state.priceDifferenceAcknowledged = true;
        }
    }),

    updateExtraEquipmentPackage: action((state, payload) => {
        const equipmentPackage = state.selectedProduct?.extraEquipmentPackages[state.selectedTab].find((x) => x.id === payload.id);
        if (equipmentPackage) {
            equipmentPackage.isSelected = payload.isSelected;
        }

        // Iterate over all tabs and update the same package, this way if an equipment package is selected in one tab, it will be selected in all tabs
        OwnershipTabArray.forEach((tab) => {
            if (tab === state.selectedTab) {
                return;
            }

            const extraEquipmentPackageOnOtherTab = state.selectedProduct?.extraEquipmentPackages[tab].find((x) => x.id === payload.id);
            if (extraEquipmentPackageOnOtherTab) {
                extraEquipmentPackageOnOtherTab.isSelected = payload.isSelected;
            }
        });

        if (!state.priceDifferenceAcknowledged) {
            state.priceDifferenceAcknowledged = true;
        }
    }),

    updateStandardEquipment: action((state, payload) => {
        const equipment = state.selectedProduct?.standardEquipment[state.selectedTab].find((x) => x.id === payload.id);
        if (equipment) {
            equipment.isSelected = payload.isSelected;
        }

        // Iterate over all tabs and update the same package, this way if an equipment package is selected in one tab, it will be selected in all tabs
        OwnershipTabArray.forEach((tab) => {
            if (tab === state.selectedTab) {
                return;
            }

            const standardEquipmentOnOtherTab = state.selectedProduct?.standardEquipment[tab].find((x) => x.id === payload.id);
            if (standardEquipmentOnOtherTab) {
                standardEquipmentOnOtherTab.isSelected = payload.isSelected;
            }
        });

        if (!state.priceDifferenceAcknowledged) {
            state.priceDifferenceAcknowledged = true;
        }
    }),

    updateExtraEquipment: action((state, payload) => {
        const equipment = state.selectedProduct?.extraEquipment[state.selectedTab].find((x) => x.id === payload.id);
        if (equipment) {
            equipment.isSelected = payload.isSelected;
        }

        // Iterate over all tabs and update the same package, this way if an equipment package is selected in one tab, it will be selected in all tabs
        OwnershipTabArray.forEach((tab) => {
            if (tab === state.selectedTab) {
                return;
            }

            const extraEquipmentOnOtherTab = state.selectedProduct?.extraEquipment[tab].find((x) => x.id === payload.id);
            if (extraEquipmentOnOtherTab) {
                extraEquipmentOnOtherTab.isSelected = payload.isSelected;
            }
        });

        if (!state.priceDifferenceAcknowledged) {
            state.priceDifferenceAcknowledged = true;
        }
    }),

    updateOptionalEquipment: action((state, payload) => {
        const equipment = state.selectedProduct?.optionalEquipment[state.selectedTab].find((x) => x.id === payload.id);
        if (equipment) {
            equipment.isSelected = payload.isSelected;
        }

        // Iterate over all tabs and update the same package, this way if an equipment package is selected in one tab, it will be selected in all tabs
        OwnershipTabArray.forEach((tab) => {
            if (tab === state.selectedTab) {
                return;
            }

            const optionalEquipmentOnOtherTab = state.selectedProduct?.optionalEquipment[tab].find((x) => x.id === payload.id);
            if (optionalEquipmentOnOtherTab) {
                optionalEquipmentOnOtherTab.isSelected = payload.isSelected;
            }
        });

        if (!state.priceDifferenceAcknowledged) {
            state.priceDifferenceAcknowledged = true;
        }
    }),

    setSelectedLeasingPeriod: action((state, payload) => {
        state.selectedLeasingPeriod = payload;
    }),
    setSelectedHireExtendedMonthlyKm: action((state, payload) => {
        state.selectedHireExtendedMonthlyKm = payload;
    }),

    setSelectedStartupDate: action((state, payload) => {
        state.selectedStartupDate = payload;
    }),

    setProductUpdateFinished: action((state, payload) => {
        state.productUpdateFinished = payload;
    }),

    setOwnershipMode: action((state, payload) => {
        state.ownershipMode = payload;
        // Sideeffect: set selected tab to avoid mismatch
        state.selectedTab = getDefaultSelectedTab(payload, state.selectedProduct);
    }),

    setSelectedTab: action((state, payload) => {
        state.selectedTab = payload;
    }),

    setFinancialPurchaseTypeDurationMonths: action((state, payload) => {
        if (!state.selectedProduct) {
            return;
        }
        state.selectedProduct.purchaseTypes.financing.durationMonths = payload.durationMonths;
    }),

    setFinancialPurchaseTypeDownPaymentPercentage: action((state, payload) => {
        state.financialPurchaseDownPaymentPercentage = payload.downPaymentPercentage;
    }),
    setDealershipHasTestDrives: action((state, payload) => {
        state.dealershipHasTestDrives = payload.hasTestDrives;
    }),
    setDealershipLocationLabel: action((state, payload) => {
        state.dealershipLocationLabel = payload.dealershipLocationLabel;
    }),
});
