import React, { FC, useEffect, useMemo, useState } from 'react';
import { EmployeesInDepartment } from '../../../components/employee/employees-in-department/employees-in-department.component';
import { DealershipContactInfo } from '../../../components/organization/dealership-contact-info/dealership-contact-info.component';
import { DemoVehiclesProductRibbon } from '../demo-vehicles-product-ribbon/demo-vehicles-product-ribbon.component';
import { OpeningHoursModule } from '../../../components/organization/opening-hours-module/opening-hours-module.component';
import { UsedCarProductRibbon } from '../../../components/organization/used-car-product-ribbon/used-car-product-ribbon.component';
import { VacantJobsModal } from '../../../components/organization/vacant-jobs-modal/vacant-jobs-modal.component';
import { BrandAndServiceModule } from '../../../components/supported-brands-and-services/brand-and-service-module/brand-and-service-module.component';

import { umbraco } from '../../../lib/api';
import { getPageByDataTypeAlias, getPageByIdGeneric } from '../../../lib/api/cms';
import { DealershipInformationPage as DealershipInformationPageType, orgTypes, Page } from '../../../lib/api/models/umbraco';
import { OrgDealershipResponse } from '../../../lib/api/organization/hessel-dealership-api';

import { getLetter } from '../../../utils/helpers/array.helpers';
import { getDealershipsForTestDrives } from '../../../utils/helpers';
import { getEmployeesByQuery } from '../../../lib/api/organization/org-api';

type DealershipPageProps = {
    page: Page;
    dealership: orgTypes.DealershipWithGeoInfo;
    siteSettings: umbraco.SiteSettings;
    metaMenu: umbraco.PageMetaMenu | null;
};

type DealershipPageInternalState = {
    allDealerships: Array<orgTypes.DealershipWithGeoInfo>;
    dealershipAndTruckNumbers: Array<orgTypes.DealerShipAndTruck>;
    emergencyPhoneNumbers: Array<orgTypes.DepartmentEmergencyNumbers>;
    dealershipWithEmployees: orgTypes.DealershipWithGeoInfo;
};

export const DealershipInformationPageMemo: FC<DealershipPageProps> = (props) => {
    const [componentState, setComponentState] = useState<DealershipPageInternalState>({
        allDealerships: [],
        dealershipAndTruckNumbers: [],
        emergencyPhoneNumbers: [],
        dealershipWithEmployees: { ...props.dealership },
    });
    const [allDealerships, setAllDealerships] = useState<DealershipInformationPageType[]>([]);
    useEffect(() => {
        // Get parent page so we can gather all dealerships
        const getContactPage = async () => {
            const [dealershipPages, error] = await getPageByDataTypeAlias('dealershipInformation');
            const [contactPage, contactPageError] = await getPageByIdGeneric<OrgDealershipResponse>(props.page.parentId);

            if (dealershipPages && dealershipPages.length > 0 && !error && contactPage && !contactPageError) {
                const typesafeDealershipPages = dealershipPages
                    .filter((x) => x.contentTypeAlias === 'dealershipInformation')
                    .map((x) => x as DealershipInformationPageType);

                setAllDealerships(typesafeDealershipPages);
                // Mercedes-Benz related info
                const mercedesDealerships = typesafeDealershipPages.filter((x) => x.supportedBrands.some((sb) => sb.brand === 'Mercedes-Benz'));

                const dealershipAndTruckNumbers = mercedesDealerships.map((x) => {
                    return {
                        emergencyNumber: x.truckEmergencyNumber,
                        autolineId: x.autolineId,
                        displayName: x.displayName,
                    };
                });

                // Map all dealerships
                const allCmsDealerships: Array<orgTypes.DealershipWithGeoInfo> = typesafeDealershipPages.map((x, index) => {
                    const typeValue = x;

                    return {
                        dealershipId: typeValue.hovedafdelingId,
                        hovedafdelingId: typeValue.hovedafdelingId,
                        header: typeValue.displayName,
                        lat: typeValue.latitude,
                        lng: typeValue.longitude,
                        city: typeValue.city,
                        zipcode: typeValue.zipcode,
                        phone: typeValue.phone,
                        address: typeValue.address,
                        email: typeValue.eMail,
                        url: x.url,
                        departments: typeValue.departments,
                        supportedBrands: typeValue.supportedBrands,
                        writeUsForm: null,
                        certificates: null,
                        letter: getLetter(index),
                        displayName: typeValue.displayName,
                        testDriveOpeningHours: {
                            openingHours: typeValue.testDriveOpeningHours?.[0]?.openingHours ?? [],
                            specialDays: typeValue.testDriveOpeningHours?.[0]?.specialDays ?? [],
                        },
                        bookWorkshopModalTexts: {
                            bookWorkshopHeader: typeValue.bookWorkshopHeader,
                            bookWorkshopDescription: typeValue.bookWorkshopDescription,
                            bookWorkshopModalButtonText: typeValue.bookWorkshopModalButton,
                        },
                        dealershipImage: typeValue.dealershipImage,
                    };
                });
                const departmentIds = [Number(props.dealership.hovedafdelingId)];
                const brandNames = props.dealership.supportedBrands.reduce((prev, curr) => [...prev, curr.brand], new Array<string>());
                const [allEmployees, error] = await getEmployeesByQuery({
                    departmentIds,
                    brandNames,
                });

                setComponentState((prev) => ({
                    allDealerships: allCmsDealerships,
                    dealershipAndTruckNumbers: dealershipAndTruckNumbers,
                    emergencyPhoneNumbers: contactPage[0].emergencyPhoneNumbers,
                    dealershipWithEmployees: { ...prev.dealershipWithEmployees, employees: allEmployees && !error ? allEmployees : [] },
                }));
            }
        };

        getContactPage();
    }, [
        props.dealership.dealershipId,
        props.dealership.departments,
        props.dealership.hovedafdelingId,
        props.dealership.supportedBrands,
        props.page.parentId,
    ]);

    const hasDemoVehicles = useMemo(() => {
        return (
            props.page.contentTypeAlias === 'dealershipInformation' &&
            ((componentState.allDealerships && componentState.dealershipWithEmployees && props.page.demoVehiclesRibbonSpot?.length > 0) ||
                (componentState.allDealerships && componentState.dealershipWithEmployees && props.page.demoVansRibbonSpot?.length > 0))
        );
    }, [componentState.allDealerships, componentState.dealershipWithEmployees, props.page]);

    const hasEmployees = useMemo(() => {
        return (
            componentState.dealershipWithEmployees &&
            componentState.dealershipWithEmployees.employees &&
            componentState.dealershipWithEmployees.employees.length > 0
        );
    }, [componentState.dealershipWithEmployees]);

    const atLeastOneWorkshopDepartment = useMemo(
        () => props.dealership.departments.some((x) => x.departmentType === 'Værksted'),
        [props.dealership.departments]
    );

    if (!props.dealership) {
        return null;
    }

    return props.page.contentTypeAlias === 'dealershipInformation' ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', backgroundColor: '#ffffff' }}>
            <DealershipContactInfo
                header={props.page.contactInformationHeader}
                description={props.page.contactInformationDescription}
                bookTimeText={props.page.contactInformationBookTime}
                writeToUsText={props.page.contactInformationWriteToUs}
                findEmployeesText={props.page.contactInformationFindEmployee}
                bookTestDriveText={props.page.contactInformationBookTestDrive}
                writeUsForm={componentState.dealershipWithEmployees?.writeUsForm ?? undefined}
                pageId={props.page.id}
                dealership={componentState.dealershipWithEmployees}
                allDealerships={componentState.allDealerships}
                hasDemoVehicles={hasDemoVehicles}
                hasEmployees={hasEmployees}
                hasAtLeastOneWorkshopDepartment={atLeastOneWorkshopDepartment}
                renderGoogleMap={true}
            />
            <OpeningHoursModule
                departments={props.page.departments}
                header={props.page.openingHoursHeader}
                description={props.page.openingHoursDescription}
                disclaimer={props.page.openingHoursDisclaimer}
                disclaimerLink={props.page.openingHoursDisclaimerLink}
                emergencyPhoneNumbers={componentState.emergencyPhoneNumbers}
                supportedBrands={props.page.supportedBrands}
                dealershipAndTruckNumbers={componentState.dealershipAndTruckNumbers}
                currentDealerId={props.page.autolineId}
                defaultSpecialDays={props.siteSettings.marketSettings[0].dealershipSettings.defaultSpecialDays}
            />

            {hasEmployees ? <EmployeesInDepartment dealership={componentState.dealershipWithEmployees} /> : null}

            <BrandAndServiceModule
                supportedBrands={props.page.supportedBrands.filter((x) => x.vehiclesInSales.length > 0 || x.vehiclesInWorkshop.length > 0)}
                header={props.page.supportedBrandHeader}
                description={props.page.supportedBrandDescription}
                certificates={componentState.dealershipWithEmployees?.certificates ?? []}
                dealership={componentState.dealershipWithEmployees}
            />

            {props.page.vacantPositionSpot && props.page.vacantPositionSpot.length > 0 ? (
                <VacantJobsModal
                    header={props.page.vacantPositionSpot[0].header}
                    description={props.page.vacantPositionSpot[0].description}
                    ctaText={props.page.vacantPositionSpot[0].ctaText}
                    image={props.page.vacantPositionSpot[0].image}
                    modalImage={props.page.vacantPositionSpot[0].modalImage}
                />
            ) : null}

            {componentState.allDealerships && componentState.dealershipWithEmployees && props.page.demoVehiclesRibbonSpot?.length ? (
                <DemoVehiclesProductRibbon
                    header={props.page.demoVehiclesRibbonSpot[0].header}
                    description={props.page.demoVehiclesRibbonSpot[0].description}
                    footerHeader={props.page.demoVehiclesRibbonSpot[0].footerHeader}
                    footerDescription={props.page.demoVehiclesRibbonSpot[0].footerDescription}
                    currentDealership={props.page}
                    allDealerships={getDealershipsForTestDrives(allDealerships)}
                    linkToPlp={props.page.demoVehiclesRibbonSpot[0].linkToPlp}
                    notFoundTexts={{
                        header: props.page.demoVehiclesRibbonSpot[0].notFoundHeader,
                        description: props.page.demoVehiclesRibbonSpot[0].notFoundDescription,
                        ctaText: props.page.demoVehiclesRibbonSpot[0].notFoundCtaText,
                    }}
                    vehicleType="Car"
                    demoVehiclesFilterConfigurationId={props.page.demoVehiclesRibbonSpot[0].filterConfiguration?.key}
                    bookTestDriveModalSettings={props.page.bookTestDriveModalSettings?.[0]}
                    defaultSpecialDays={props.siteSettings.marketSettings[0].dealershipSettings.defaultSpecialDays}
                />
            ) : null}

            {componentState.allDealerships && componentState.dealershipWithEmployees && props.page.demoVansRibbonSpot?.length ? (
                <DemoVehiclesProductRibbon
                    header={props.page.demoVansRibbonSpot[0].header}
                    description={props.page.demoVansRibbonSpot[0].description}
                    footerHeader={props.page.demoVansRibbonSpot[0].footerHeader}
                    footerDescription={props.page.demoVansRibbonSpot[0].footerDescription}
                    currentDealership={props.page}
                    allDealerships={getDealershipsForTestDrives(allDealerships)}
                    linkToPlp={props.page.demoVansRibbonSpot[0].linkToPlp}
                    notFoundTexts={{
                        header: props.page.demoVansRibbonSpot[0].notFoundHeader,
                        description: props.page.demoVansRibbonSpot[0].notFoundDescription,
                        ctaText: props.page.demoVansRibbonSpot[0].notFoundCtaText,
                    }}
                    vehicleType="Van"
                    demoVehiclesFilterConfigurationId={props.page.demoVansRibbonSpot[0].filterConfiguration?.key}
                />
            ) : null}

            {props.page.usedCarsRibbonSpot && props.page.usedCarsRibbonSpot.length > 0 ? (
                <UsedCarProductRibbon
                    header={props.page.usedCarsRibbonSpot[0].header}
                    description={props.page.usedCarsRibbonSpot[0].description}
                    seeAllLink={props.page.usedCarsRibbonSpot[0].link}
                    usedCarsFilterConfigurationId={props.page.usedCarsRibbonSpot[0].filterConfiguration?.key}
                    currentDealershipId={props.page.hovedafdelingId}
                />
            ) : null}
        </div>
    ) : (
        <p>{props.page.contentTypeAlias}</p>
    );
};

export const DealershipInformationPage = React.memo(DealershipInformationPageMemo);
