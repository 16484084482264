import { FC, useState } from 'react';
import { ContentSpots } from '../spots';
import { ContentSpotSettings } from '../../lib/api/models/umbraco/content-spot';
import { DealershipInformationPage } from '../../lib/api/models/umbraco';
import { useRouter } from 'next/router';
import { useEffectOnce } from 'react-use';
import { getPageByDataTypeAlias, umbraco } from '../../lib/api';
import { getEmployeesByIds } from '../../lib/api/organization/org-api';
import { DealershipEmployee } from '../../lib/api/models/umbraco/organization.types';
import EmployeeContactInfoCard from '../shared/employee-contact-info-card/employee-contact-info-card.component';
import { postSolutionsAcceptEmailedOffer } from '../../lib/api/solutions/hessel-solution-accept-emailed-offer-api';

type Props = { page: umbraco.SolutionsAcceptEmailedOfferPage };
enum ResponseNumber {
    Success = 0,
    Fail = 1,
    Expired = 2,
    AlreadyAccepted = 3,
}

const SolutionsAcceptEmailedOfferPage: FC<Props> = ({ page }) => {
    const router = useRouter();
    const [responseContent, setResponseContent] = useState<ContentSpotSettings[]>();
    const [salesPerson, setSalesPerson] = useState<DealershipEmployee>();
    const [department, setDepartment] = useState<DealershipInformationPage>();
    const [systemHasFailed, setSystemHasFailed] = useState<boolean>(false);

    useEffectOnce(() => {
        async function acceptOffer() {
            let response = undefined;
            let error = undefined;
            try {
                [response, error] = await postSolutionsAcceptEmailedOffer(router.query.offerId as string);
            } catch (ex) {
                error = ex;
            }

            if (response && !error) {
                const responseNumber = response.responseCode as ResponseNumber;
                switch (responseNumber) {
                    case ResponseNumber.Success:
                        setResponseContent(page.successContent);
                        break;
                    case ResponseNumber.Expired:
                        setResponseContent(page.expiredContent);
                        break;
                    case ResponseNumber.AlreadyAccepted:
                        setResponseContent(page.alreadyAcceptedContent);
                        break;
                    case ResponseNumber.Fail:
                    default:
                        setResponseContent(page.failContent);
                        setSystemHasFailed(true);
                        break;
                }
            } else if (error && !response) {
                setResponseContent(page.failContent);
                setSystemHasFailed(true);
            }
        }

        acceptOffer();

        async function getSalesPerson() {
            const [response, error] = await getEmployeesByIds([router.query.salesPerson as string]);
            if (response && !error) {
                setSalesPerson(response[0]);
                await getDepartment(response[0]);
            }
        }

        async function getDepartment(salesPerson: DealershipEmployee) {
            const [departmentsResponse] = await getPageByDataTypeAlias('dealershipInformation');
            const department = (departmentsResponse as DealershipInformationPage[]).find(
                (d) => Number(d.hovedafdelingId) === salesPerson.departmentId
            );
            if (department) {
                setDepartment(department);
            }
        }

        getSalesPerson();
    });

    return (
        <>
            {responseContent ? <ContentSpots context={{ pageId: page.id }} contentSpotSettings={responseContent}></ContentSpots> : null}
            {salesPerson && department && !systemHasFailed && (
                <EmployeeContactInfoCard
                    title={page.employeeCardTitle}
                    subTitle={page.employeeCardSubtitle}
                    employee={salesPerson}
                    department={department}
                ></EmployeeContactInfoCard>
            )}
        </>
    );
};

export default SolutionsAcceptEmailedOfferPage;
