import { FC, useEffect, useMemo } from 'react';
import { StyledBoxSection, StyledCartPageGrid, StyledCartPageHeader, StyledCartPageWrapper } from './cart-page.styled';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { Orderline } from './orderline/orderline.component';
import { CartSummary } from './summary/cart-summary.component';
import { CartStoreContext } from '../../../lib/state/shop/cart/cart.store';
import { ShopCheckoutDisclaimer } from '../checkout/checkout-disclaimer/checkout-disclaimer.component';
import { CartPage as UmbracoCartPage, sharedTypes } from '../../../lib/api/models/umbraco';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { ContentSpots } from '../../spots';
import { AddVoucher } from './voucher/add-voucher.component';

type IProps = {
    paymentIcons: Array<sharedTypes.Image>;
    page: UmbracoCartPage;
};

export const CartPage: FC<IProps> = ({ paymentIcons, page }) => {
    const { cart } = CartStoreContext.useStoreState((state) => state);
    const itemsTotal = useMemo(() => {
        return cart ? cart.lineItems.reduce((prev, curr) => prev + curr.quantity, 0) : 0;
    }, [cart]);

    const { trackShopEvents } = useGtmTracking();
    const tracker = trackShopEvents();

    useEffect(() => {
        if (cart) tracker.viewCart(cart);
    }, [cart, tracker]);

    const anyCart = useMemo(() => {
        return cart && cart.lineItems.length > 0;
    }, [cart]);

    const installationDisclaimer = false; //TODO INSTALLATION
    return (
        <>
            <StyledCartPageWrapper>
                <CenteredBlock>
                    {anyCart ? (
                        <>
                            <StyledCartPageHeader>Kurv ({itemsTotal})</StyledCartPageHeader>
                            <StyledCartPageGrid>
                                <StyledBoxSection>
                                    {installationDisclaimer && (
                                        <div style={{ marginBottom: '25px' }}>
                                            <ShopCheckoutDisclaimer
                                                disclaimerText={
                                                    'Du har produkter i kurven, som kun tillader afhentning i butik, hvorved hele din ordre skal afhentes i butikken'
                                                }
                                            />
                                        </div>
                                    )}
                                    {cart?.lineItems.map((x) => {
                                        return <Orderline orderline={x} key={x.id} />;
                                    })}
                                </StyledBoxSection>
                                <div>
                                    <StyledBoxSection>
                                        <CartSummary paymentIcons={paymentIcons} />
                                    </StyledBoxSection>
                                    <AddVoucher />
                                </div>
                            </StyledCartPageGrid>
                        </>
                    ) : (
                        <>
                            <StyledCartPageHeader>Din kurv er tom</StyledCartPageHeader>
                        </>
                    )}
                </CenteredBlock>
            </StyledCartPageWrapper>
            {!anyCart && <ContentSpots contentSpotSettings={page.emptyBasketContent} context={{ pageId: page.id }} />}
        </>
    );
};
