import { useEffect, useMemo, useState, VFC } from 'react';
import { DealershipInformationPage, hireCheckoutTypes } from '../../../../../lib/api/models/umbraco';
import { LeadDurationDeliveryStore } from '../../../../../lib/state/hessel-site/lead-checkout/duration-delivery';
import { LeadCheckoutStepsStore } from '../../../../../lib/state/hessel-site/lead-checkout/steps';
import { ProductDetailsStore } from '../../../../../lib/state/hessel-site/product-details';
import { DateStyle, formatDate, handleUrlClick, sanitizeMarkup } from '../../../../../utils/helpers';
import { generateRange } from '../../../../../utils/helpers/array.helpers';
import { DropdownInput } from '../../../../forms/inputs';
import { DropdownOption } from '../../../../forms/inputs/dropdown/dropdown-input.props';
import { Button, CheckBox, DatePickerDropdown, LabelWithLinkComponent, SimpleModal } from '../../../../shared';
import { useUI } from '../../../../ui';
import { StepQuestion } from '../../typography';
import { BorderBox, Section, Content, SectionNote, TermsAndConditions, DurationTermsModalContent } from './duration-delivery.styled';
import { getPageByDataTypeAlias, getSiteSettings } from '../../../../../lib/api';
import { SpecialDay } from '../../../../../lib/api/models/umbraco/organization.types';
import { ShopCheckoutDisclaimer } from '../../../../shop/checkout/checkout-disclaimer/checkout-disclaimer.component';
import { addDays } from 'date-fns';

type Props = {
    stepNumber: number;
    content: hireCheckoutTypes.HireStepDelivery;
    canValidateStep: boolean;
    fraudDisclaimerText: string;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const DurationDelivery: VFC<Props> = ({ stepNumber, content, canValidateStep, fraudDisclaimerText }) => {
    const [departments, setDepartments] = useState<DealershipInformationPage[]>([]);

    useEffect(() => {
        const getDepartments = async () => {
            const [response, error] = await getPageByDataTypeAlias('dealershipInformation');
            if (response && !error) {
                setDepartments(response as DealershipInformationPage[]);
            }
        };
        getDepartments();
    }, []);

    // Duration & delivery store
    const {
        deliveryMode,
        privacyPolicyAccepted: termsAccepted,
        termsAccepted: subscribed,
        showEmployees,
        deliveryStepIsValid,
        employees,
        selectedDepartment,
        selectedEmployeeId,
    } = LeadDurationDeliveryStore.useStoreState((state) => state);

    const {
        setPrivacyPolicyAccepted: setTermsAccepted,
        setTermsAccepted: setSubscribed,
        setShowEmployees,
        getEmployeesThunk,
        setSelectedDepartment,
        setSelectedEmployeeId,
    } = LeadDurationDeliveryStore.useStoreActions((actions) => actions);

    // Steps Store
    const { setStepValidity } = LeadCheckoutStepsStore.useStoreActions((actions) => actions);

    // Product details store
    const { selectedLeasingPeriod, selectedStartupDate, selectedProduct } = ProductDetailsStore.useStoreState((state) => state);
    const { setSelectedLeasingPeriod, setSelectedStartupDate } = ProductDetailsStore.useStoreActions((actions) => actions);

    useEffect(() => {
        setStepValidity({ isValid: !!selectedStartupDate && deliveryStepIsValid, stepNumber });
    }, [deliveryStepIsValid, selectedStartupDate, setStepValidity, stepNumber]);

    // Terms Modal
    const [showTerms, setShowTerms] = useState(false);
    const uiHandler = useUI();

    // Leasing Period
    const leasingMonths = useMemo(() => {
        return [selectedProduct?.purchaseTypes.hire.durationFrom ?? 0, selectedProduct?.purchaseTypes.hire.durationTo ?? 0];
    }, [selectedProduct]);

    const leasingPeriodOptions = useMemo(() => {
        const [start, stop] = leasingMonths;

        const fullList = generateRange(start, stop, 1);

        return fullList
            .sort((a, b) => a - b)
            .map<DropdownOption<string>>((p) => ({
                displayValue: `${p} mdr.`,
                value: `${p}`,
            }));
    }, [leasingMonths]);

    const selectedLeasingPeriodIsValid = useMemo(() => (selectedLeasingPeriod?.value ? true : false), [selectedLeasingPeriod?.value]);

    // Department
    const dealershipOptions = useMemo<DropdownOption<string>[]>(() => {
        if (!selectedProduct || !selectedProduct.brand) return [];
        const filtered = departments.filter((x) => {
            const brandSupport = x.supportedBrands.find((x) => x.brand === selectedProduct.brand);
            const salesSupport = brandSupport && brandSupport.vehiclesInSales.find((x) => x === 'Cars');
            if (brandSupport && salesSupport) return true;
            return false;
        });

        return filtered
            .sort((a, b) => {
                if (a.dealershipLabel < b.dealershipLabel) {
                    return -1;
                }

                if (a.dealershipLabel > b.dealershipLabel) {
                    return 1;
                }

                return 0;
            })
            .map((x) => ({ displayValue: x.dealershipLabel, value: `${x.hovedafdelingId}` }));
    }, [departments, selectedProduct]);

    const selectedDepartmentOption = useMemo(() => {
        return dealershipOptions.find(({ value }) => value === `${selectedDepartment?.hovedafdelingId}`);
    }, [dealershipOptions, selectedDepartment]);

    const selectedDepartmentIsValid = useMemo(() => (selectedDepartment?.hovedafdelingId ? true : false), [selectedDepartment]);

    //specialDays
    const [specialDays, setSpecialDays] = useState<Array<SpecialDay>>();
    useEffect(() => {
        const getSpecialDays = async () => {
            const [response, error] = await getSiteSettings();
            if (response && !error) {
                setSpecialDays(response.marketSettings?.[0]?.dealershipSettings?.defaultSpecialDays ?? []);
            }
        };

        getSpecialDays();
    }, []);
    // Get Employees
    useEffect(() => {
        if (selectedDepartmentOption) {
            getEmployeesThunk({
                departmentId: +selectedDepartmentOption?.value,
                brand: selectedProduct?.brand ?? '',
            });
        }
    }, [getEmployeesThunk, selectedDepartmentOption, selectedProduct?.brand]);

    // Employees
    const departmentEmployeesOptions = useMemo<DropdownOption<string>[]>(() => {
        return employees.map((x) => ({ displayValue: x.name, value: `${x.employeeId}` }));
    }, [employees]);

    const selectedEmployee = useMemo(() => {
        return departmentEmployeesOptions.find(({ value }) => value === `${selectedEmployeeId}`);
    }, [departmentEmployeesOptions, selectedEmployeeId]);

    const selectedEmployeeIsValid = useMemo(() => (selectedEmployee?.value ? true : false), [selectedEmployee?.value]);

    const latestPickupDate = useMemo(() => {
        if (!selectedProduct) return undefined;
        if (selectedProduct.availability === 'InStock' && selectedProduct.purchaseTypes.hire.latestDeliveryDate) {
            return new Date(selectedProduct.purchaseTypes.hire.latestDeliveryDate);
        } else {
            return addDays(new Date(), content.orderCarLatestDeliveryInDays);
        }
    }, [content.orderCarLatestDeliveryInDays, selectedProduct]);

    return (
        <BorderBox>
            <Section>
                <StepQuestion>
                    {content.durationSectionHeadline.replace('{{car_info}}', `${selectedProduct?.brand} ${selectedProduct?.variantTitle}`)}
                </StepQuestion>
                <Content>
                    <DropdownInput
                        options={leasingPeriodOptions}
                        value={selectedLeasingPeriod}
                        id="duration-and-delivery-periods-dropdown"
                        label={content.durationDropdownLabel}
                        placeholder={content.durationDropdownPlaceholder}
                        disabled={leasingPeriodOptions.length === 0}
                        onChange={(option) => {
                            if (option) {
                                setSelectedLeasingPeriod(option);
                            }
                        }}
                        isValid={selectedLeasingPeriodIsValid}
                        canValidateInputField={canValidateStep}
                        validationMessage={content.durationDropdownValidationMessage}
                        onInputBlur={() => null}
                    />
                </Content>
            </Section>

            <Section>
                <StepQuestion>{content.deliverySectionHeadline}</StepQuestion>

                {deliveryMode === 'dealershipPickup' ? (
                    <>
                        <Content>
                            <DropdownInput
                                id="dealership-list-dropdown"
                                options={dealershipOptions}
                                value={selectedDepartmentOption}
                                label={content.dealershipDropdownLabel}
                                placeholder={content.dealershipDropdownPlaceholder}
                                disabled={leasingPeriodOptions.length === 0}
                                onChange={async (option) => {
                                    if (option) {
                                        const newDepartment = departments.find((x) => x.hovedafdelingId === option.value);
                                        if (newDepartment) {
                                            setSelectedDepartment(newDepartment);
                                        }
                                    }
                                }}
                                isValid={selectedDepartmentIsValid}
                                canValidateInputField={canValidateStep}
                                validationMessage={content.dealershipDropdownValidationMessage}
                                onInputBlur={() => null}
                            />
                        </Content>
                        <Button onClick={() => setShowEmployees(!showEmployees)} variant="link" alignSelf="left">
                            {showEmployees ? 'Skjul medarbejdere' : content.showEmployeesDropdownText}
                        </Button>
                    </>
                ) : null}
            </Section>

            {/* Employees dropdown */}
            {showEmployees && deliveryMode === 'dealershipPickup' ? (
                <Section>
                    <StepQuestion>{content.employeesDropdownHeadline}</StepQuestion>
                    <Content>
                        <DropdownInput
                            options={departmentEmployeesOptions}
                            value={selectedEmployee}
                            id="dealership-employees"
                            label={content.employeesDropdownLabel}
                            placeholder={content.employeesDropdownPlaceholder}
                            disabled={departmentEmployeesOptions.length === 0}
                            onChange={(option) => {
                                if (option) {
                                    setSelectedEmployeeId(+option.value);
                                }
                            }}
                            isValid={selectedEmployeeIsValid}
                            canValidateInputField={false}
                        />
                    </Content>
                </Section>
            ) : null}

            {/* Date  */}
            <Section>
                <StepQuestion>{content.leasingStartHeadline}</StepQuestion>
                <Content>
                    <DatePickerDropdown
                        id="delivery-date-dropdown"
                        placeholder={content.deliveryDateDropdownPlaceholder}
                        value={
                            selectedStartupDate
                                ? {
                                      displayValue: formatDate(selectedStartupDate, DateStyle.dk_full_text),
                                      value: formatDate(selectedStartupDate, DateStyle.dk_full_text),
                                  }
                                : undefined
                        }
                        isValid={!!selectedStartupDate}
                        canValidateInputField={canValidateStep}
                        label={content.deliveryDateDropdownLabel}
                        onChange={() => null}
                        selectedDay={selectedStartupDate}
                        onDayChange={setSelectedStartupDate}
                        validationMessage={content.deliveryDateDropdownValidationMessage}
                        waitingDays={selectedProduct?.availability === 'InStock' ? content.inStockCarWaitingDays : content.orderCarWaitingDays}
                        includeWeekends={false}
                        specialDays={specialDays?.filter((x) => x.closed).map((x) => new Date(x.date))}
                        latestDate={latestPickupDate}
                    />
                </Content>
                <SectionNote>{content.deliveryDateDropdownDisclaimer}</SectionNote>
            </Section>

            <TermsAndConditions>
                <CheckBox
                    id="personal-data-policy"
                    value="0"
                    checked={!!termsAccepted}
                    onChange={(checked) => setTermsAccepted(checked)}
                    textAlign="top"
                    isValid={!canValidateStep || termsAccepted}
                    required={true}
                    validationMessage={content.privacyPolicyValidationMessage}
                >
                    <LabelWithLinkComponent
                        labelText={content.privacyPolicyLabel}
                        termsLinkText={content.privacyPolicyLinkText}
                        onTermsLinkClick={() => handleUrlClick(content.privacyPolicyLink)}
                        onTradeConditionsLinkClick={() => handleUrlClick(content.tradeConditionsLink)}
                        tradeConditionsLinkText={content.tradeConditionsLinkText}
                    />
                </CheckBox>

                <CheckBox
                    id="news-letter-subscription"
                    value="1"
                    checked={!!subscribed}
                    onChange={(checked) => setSubscribed(checked)}
                    textAlign="top"
                    isValid={true}
                >
                    <LabelWithLinkComponent
                        labelText={content.termsLabel}
                        termsLinkText={content.termsLinkText}
                        onTermsLinkClick={() => setShowTerms(true)}
                    />
                </CheckBox>

                <SimpleModal
                    headerText={content.termsModalHeader}
                    isVisible={showTerms}
                    closeAction={() => {
                        setShowTerms(false);
                        uiHandler.applyScroll();
                    }}
                    closeText={content.termsModalCloseButtonText}
                >
                    <DurationTermsModalContent {...sanitizeMarkup(content.termsContent.replace('/media/', `${MEDIA_URL}/media/`))} />
                </SimpleModal>
            </TermsAndConditions>

            <ShopCheckoutDisclaimer disclaimerText={fraudDisclaimerText} />
        </BorderBox>
    );
};
