import { useState, VFC } from 'react';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { PreviewLink, Separator } from '../../../shared';
import { CarSummary } from './car-summary';
import { BorderBox, SectionWrapper, TextGroup, Label, StyledValue, PreviewLinks, StyledToggler } from './order-summary-mobile.styled';
import { SummaryToggler } from './summary-toggler';
import { TotalNoMoms } from './total-no-moms';
import { TotalPrice } from './total-price';
import { SvgIcon } from '../../../shared/svg-icon';
import { hesselViewModels } from '../../../../lib/view-models';
import { HireCheckoutMode } from '../../../../lib/state/hessel-site/lead-checkout/forms';
import { handleUrlClick } from '../../../../utils/helpers';
import { umbraco } from '../../../../lib/api';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { useUI } from '../../../ui';

export const OrderSummaryMobile: VFC<
    hesselViewModels.OrderSummary & {
        showPaymentPlanSidebar: () => void;
        checkoutMode: HireCheckoutMode;
        leasingAgreementLink: Array<umbraco.HirePageLinkAndIcon>;
        seePaymentPlanLinkText: string;
        seePaymentPlanLinkIcon?: sharedTypes.Image;
    }
> = ({
    imageUrl,
    model,
    leasingPeriod,
    carPrice,
    colorName,
    colorImageUrl,
    extraEquipmentPrice,
    optionalEquipmentPrice: addonsPrice,
    totalPriceIncludingMoms,
    totalPriceExcludingMoms,
    startupDate,
    brandTitle,
    variantTitle,
    showPaymentPlanSidebar,
    checkoutMode,
    leasingAgreementLink,
    seePaymentPlanLinkText,
    seePaymentPlanLinkIcon,
    establishmentFee,
}) => {
    const isSmallScreen = useMediaQuery({ target: 'laptop_tablet_landscape' });
    const [showDetails, setShowDetails] = useState(false);

    const uiHandler = useUI();

    const hideScrollBars = () => {
        uiHandler.removeScrollAndLock();
    };

    if (!isSmallScreen) {
        return null;
    }

    return (
        <>
            <BorderBox>
                <SummaryToggler
                    imageUrl={imageUrl}
                    price={totalPriceIncludingMoms}
                    brand={brandTitle ?? ''}
                    model={variantTitle ?? ''}
                    showDetails={() => {
                        setShowDetails(true);
                        hideScrollBars();
                    }}
                />
            </BorderBox>

            <SidePanel
                isVisible={showDetails}
                cancelAction={() => {
                    uiHandler.applyScroll();
                    setShowDetails(false);
                }}
            >
                <SidePanelLayout
                    closeSidePanel={() => {
                        uiHandler.applyScroll();
                        setShowDetails(false);
                    }}
                >
                    <CarSummary imageUrl={imageUrl} brand={brandTitle ?? ''} model={model} colorName={colorName} colorUrl={colorImageUrl} />

                    <Separator marginBottom="0px" marginTop="px" />

                    <SectionWrapper>
                        <TextGroup>
                            <Label>Leasingperiode {startupDate ? `(Opstart fra ${startupDate?.toLocaleDateString('da-DK')})` : null}</Label>
                            <StyledValue>{leasingPeriod}</StyledValue>
                        </TextGroup>
                    </SectionWrapper>

                    <Separator marginBottom="0px" marginTop="px" />

                    <SectionWrapper>
                        <TextGroup>
                            <Label>Bilens pris</Label>
                            <StyledValue>{carPrice}</StyledValue>
                        </TextGroup>
                        <TextGroup>
                            <Label>Ekstraudstyr</Label>
                            <StyledValue>{extraEquipmentPrice}</StyledValue>
                        </TextGroup>
                        <TextGroup>
                            <Label>Tilvalg</Label>
                            <StyledValue>{addonsPrice}</StyledValue>
                        </TextGroup>
                    </SectionWrapper>

                    <Separator marginBottom="0px" marginTop="px" />

                    <TotalPrice totalPrice={totalPriceIncludingMoms} establishmentFee={establishmentFee} />

                    <Separator marginBottom="0px" marginTop="px" />

                    {checkoutMode === HireCheckoutMode.Business ? <TotalNoMoms totalPriceNoMoms={totalPriceExcludingMoms} /> : null}

                    <PreviewLinks>
                        <PreviewLink
                            text={seePaymentPlanLinkText}
                            onClick={() => {
                                showPaymentPlanSidebar();
                            }}
                            icon={seePaymentPlanLinkIcon?.src ?? ''}
                        />
                        {leasingAgreementLink.length > 0 ? (
                            <PreviewLink
                                text={leasingAgreementLink[0].link?.name}
                                icon={leasingAgreementLink[0].icon?.src}
                                onClick={() => {
                                    const url = leasingAgreementLink[0].link;

                                    if (url) {
                                        handleUrlClick(url);
                                    }
                                }}
                            />
                        ) : null}
                    </PreviewLinks>

                    <StyledToggler
                        onClick={() => {
                            setShowDetails(false);
                            uiHandler.applyScroll();
                        }}
                    >
                        <SvgIcon iconName="chevron/left" color="primary" />
                        &nbsp;Luk detaljer
                    </StyledToggler>
                </SidePanelLayout>
            </SidePanel>
        </>
    );
};
