import React, { FC, useMemo } from 'react';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { BookAdvisorModal } from '../book-advisor-modal/book-advisor-modal.component';
import { CarsLeasingTypesModal } from '../cars/cars-leasing-types-modal/cars-leasing-types-modal.component';
import { EnquireAboutCarModal } from '../cars/enquire-about-car-modal/enquire-about-car-modal.component';
import { FinancialCalculationsModal } from '../financial-calculations-modal/financial-calculations-modal.component';
import { OrderCarModal } from '../order-car-modal/order-car-modal.component';
import { OpenDialog, usePdpModals } from '../product-details-page/product-details-page.context';
import { VansLeasingTypesModal } from '../vans/vans-leasing-types-modal/vans-leasing-types-modal.component';
import { ProductDetailsPage } from '../../../lib/api/models/umbraco';
import { BenefitsDialog } from '../benefits-dialog/benefits-dialog.component';
import { BookTestDriveModal } from '../../organization/book-test-drive-modal/book-test-drive-modal.component';
import { usePdpDealerships } from '../product-details-page/hooks/use-pdp-dealerships';
import { ContactUsPdpForm } from '../contact-us/contact-us-pdp-form.component';
import { umbraco } from '../../../lib/api';
import { TradeInCarAutoProffModal } from '../product-details-page/trade-in-car/trade-in-car-autoproff-modal.component';
import { ModalWithEditorContent } from '../../shared/modals/modal-with-editor-content/modal-with-editor-content.component';

type Props = {
    page: ProductDetailsPage;
    siteSettings?: umbraco.SiteSettings;
    isBusinessContext: boolean;
};

/**
 * This component is responsible for rendering all the modals that are used in the product details page.
 */
export const ProductDetailsModals: FC<Props> = (props) => {
    const { selectedProduct, selectedColor, computedCashPrice, selectedTab } = ProductDetailsStore.useStoreState((state) => state);
    const { closeModal, openedDialog } = usePdpModals();

    const { dealerships, starmarkDealerships } = usePdpDealerships();

    const dealershipHasTestDriveOption = useMemo(() => {
        if (dealerships && selectedProduct && selectedProduct.locationId) {
            const dealership = dealerships.find((x) => x.hovedafdelingId === selectedProduct.locationId?.toString());
            return (
                (selectedProduct.availability !== 'Used' && selectedProduct.availability !== 'Engros') ||
                (dealership && dealership.testDriveOpeningHours.length > 0)
            );
        }
        return true;
    }, [dealerships, selectedProduct]);

    if (!selectedProduct) {
        return null;
    }

    return (
        <>
            {dealershipHasTestDriveOption && (
                <BookTestDriveModal
                    allDealerships={dealerships || []}
                    onClose={closeModal}
                    visible={openedDialog === OpenDialog.BookTrial}
                    brand={selectedProduct.brand}
                    brandModel={selectedProduct.brand + ' ' + selectedProduct.variantTitle}
                    vehicleItemNumber={selectedProduct.itemNumber}
                    name={selectedProduct.name}
                    vehicleType={selectedProduct.vehicleType}
                    configurationId={props.page.bookTestDriveModalFilterConfiguration?.key}
                    bookTestDriveModalSettings={props.page.bookTestDriveModalSettings[0]}
                    vehicleLocationId={selectedProduct.locationId}
                    isUsedCar={selectedProduct.availability === 'Used'}
                    vehicleUrl={selectedProduct.url}
                    vehicleAvailability={selectedProduct.availability}
                    specialDays={props.siteSettings?.marketSettings[0].dealershipSettings.defaultSpecialDays}
                    testDriveAvailability={selectedProduct.testDrive}
                    referenceNumber={selectedProduct.referenceNumber}
                    vehicleEntityId={selectedProduct.entityId.toString()}
                    vehicleTrackingPrice={selectedProduct.purchaseTypes.cash.basePrice}
                />
            )}

            {props.page.contactForm ? (
                <ContactUsPdpForm
                    onClose={closeModal}
                    isVisible={openedDialog === OpenDialog.ContactHessel}
                    dealerships={dealerships ?? []}
                    starmarkDealership={starmarkDealerships ?? []}
                    formSetup={props.page.contactForm}
                    isBusinessContext={props.isBusinessContext}
                />
            ) : null}

            <BenefitsDialog content={props.page.hesselHireBenefitsDialog[0]} isVisible={openedDialog === OpenDialog.Benefits} onClose={closeModal} />

            <FinancialCalculationsModal
                visible={openedDialog === OpenDialog.FinancialCalculations}
                onClose={closeModal}
                product={selectedProduct}
                modalSettings={props.page.financeCalculationsModalSettings[0]}
                cashPrice={computedCashPrice}
                page={props.page}
            />

            <OrderCarModal
                allDealerships={dealerships || []}
                visible={openedDialog === OpenDialog.OrderCarFormModal}
                onClose={closeModal}
                product={selectedProduct}
                color={selectedColor}
                page={props.page}
                selectedTab={selectedTab}
                vehicleLocationId={selectedProduct.locationId}
                isUsedCar={selectedProduct.availability === 'Used'}
            />

            {selectedProduct.vehicleType === 'Van' ? (
                <VansLeasingTypesModal
                    visible={openedDialog === OpenDialog.CompareVansLeasingTypes}
                    onClose={closeModal}
                    modalSettings={props.page.compareVansLeasingTypesModal[0]}
                />
            ) : null}

            {selectedProduct.purchaseTypes.hire.show && selectedProduct.purchaseTypes.privateLeasing.show ? (
                <CarsLeasingTypesModal
                    visible={openedDialog === OpenDialog.CompareCarsLeasingTypes}
                    onClose={closeModal}
                    modalSettings={props.page.compareCarsLeasingTypesModal[0]}
                />
            ) : null}

            {selectedProduct.vehicleType === 'Van' ? (
                <BookAdvisorModal
                    visible={openedDialog === OpenDialog.BookVanAdvisor}
                    onClose={closeModal}
                    modalProps={props.page.vanBookAdvisorModal?.[0]}
                    pageId={props.page.key}
                />
            ) : null}

            {selectedProduct.vehicleType === 'Car' ? (
                <BookAdvisorModal
                    visible={openedDialog === OpenDialog.BookCompanyCarAdvisor}
                    onClose={closeModal}
                    modalProps={props.page.companyCarBookAdvisorModal?.[0]}
                    pageId={props.page.key}
                />
            ) : null}

            {selectedProduct.availability === 'Used' && selectedProduct.vehicleType === 'Car' ? (
                <EnquireAboutCarModal
                    visible={openedDialog === OpenDialog.EnquireAboutCarModal}
                    onClose={closeModal}
                    form={props.page.enquireAboutCarModal[0]}
                    pageId={props.page.key}
                    vehicleId={selectedProduct.id}
                />
            ) : null}

            <TradeInCarAutoProffModal visible={openedDialog === OpenDialog.AutoProff} onClose={closeModal} />

            <ModalWithEditorContent
                content={props.page.engrosExplanationSpot.modalContent}
                onClose={closeModal}
                visible={openedDialog === OpenDialog.EngrosExplainer}
                closeCta={true}
            ></ModalWithEditorContent>

            <ModalWithEditorContent
                content={props.page.tradeInCarModalContent}
                onClose={closeModal}
                visible={openedDialog === OpenDialog.AutoProffExplainer}
                closeCta={true}
            ></ModalWithEditorContent>
        </>
    );
};
