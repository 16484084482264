import { useCallback, VFC } from 'react';
import { usePushError } from '../../../error-handling';
import { ErrorType, umbraco } from '../../../lib/api';
import { hireCheckoutTypes, sharedTypes } from '../../../lib/api/models/umbraco';
import { LeadDurationDeliveryStore } from '../../../lib/state/hessel-site/lead-checkout/duration-delivery';
import { LeadCheckoutFormsStore } from '../../../lib/state/hessel-site/lead-checkout/forms';
import { convertHireCheckoutStepsToInputs } from '../../../lib/state/hessel-site/lead-checkout/forms/hire-forms.helpers';
import { LeadCheckoutStepsStore } from '../../../lib/state/hessel-site/lead-checkout/steps';
import { convertHireCheckoutStepToStepState } from '../../../lib/state/hessel-site/lead-checkout/steps/steps.helpers';
import { LeadSite } from './lead-site';

export type Props = {
    checkoutSteps: Array<hireCheckoutTypes.HireCheckoutStep>;
    helpSettings: Array<umbraco.CustomerHelpCenter>;
    headerSettings: umbraco.HireHeaderSettings;
    noCarData?: umbraco.HireLeadCheckoutNoCar;
    leasingAgreementLink: Array<umbraco.HirePageLinkAndIcon>;
    seePaymentPlanLinkText: string;
    seePaymentPlanLinkIcon?: sharedTypes.Image;
    miniBasketSettings: umbraco.HireMiniBasketSettings;
};

export const HireLeadCheckout: VFC<Props> = ({
    checkoutSteps,
    helpSettings,
    headerSettings,
    noCarData,
    leasingAgreementLink,
    seePaymentPlanLinkText,
    seePaymentPlanLinkIcon,
    miniBasketSettings,
}) => {
    const { pushError } = usePushError();

    const push = useCallback(
        (error: ErrorType) => {
            pushError(error);
        },
        [pushError]
    );

    return (
        <LeadCheckoutStepsStore.Provider
            runtimeModel={{ currentStep: 0, steps: checkoutSteps?.map(convertHireCheckoutStepToStepState) ?? [] }}
            injections={(previousInjections) => ({ ...previousInjections, pushError: push })}
        >
            <LeadCheckoutFormsStore.Provider
                runtimeModel={{
                    inputs: checkoutSteps.map(convertHireCheckoutStepsToInputs).reduce((acc, val) => [...acc, ...val], []),
                }}
                injections={(previousInjections) => ({ ...previousInjections, pushError: push })}
            >
                <LeadDurationDeliveryStore.Provider>
                    <LeadSite
                        helpSettings={helpSettings}
                        headerSettings={headerSettings}
                        noCarData={noCarData}
                        leasingAgreementLink={leasingAgreementLink}
                        seePaymentPlanLinkText={seePaymentPlanLinkText}
                        seePaymentPlanLinkIcon={seePaymentPlanLinkIcon}
                        miniBasketSettings={miniBasketSettings}
                    />
                </LeadDurationDeliveryStore.Provider>
            </LeadCheckoutFormsStore.Provider>
        </LeadCheckoutStepsStore.Provider>
    );
};
