import React, { VFC } from 'react';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { RelewiseRelatedVehicles } from '../../../spots/relewise-related-vehicles/relewise-related-vehicles.component';

type IProps = {
    header: string;
    subText: string;
};

export const PdpRelatedVehiclesRibbonMemo: VFC<IProps> = ({ header, subText }) => {
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);

    if (!selectedProduct) return null;

    return (
        <div style={{ margin: '12px 0' }}>
            <RelewiseRelatedVehicles
                spot={{
                    alias: 'relewiseRelatedVehicles',
                    entityId: selectedProduct.entityId.toString(),
                    fromDate: new Date('0001-01-01T00:00:00'),
                    toDate: new Date('0001-01-01T00:00:00'),
                    hideBlock: false,
                    scrollAnchorId: '',
                    header,
                    subText,
                }}
            />
        </div>
    );
};

export const PdpRelatedVehiclesRibbon = React.memo(PdpRelatedVehiclesRibbonMemo);
