import { FC } from 'react';
import reactStringReplace from 'react-string-replace';
import { getUniqueId } from '../../../utils/helpers';
import { LabelText } from './label-with-link.styled';

type IProps = {
    labelText: string;
    termsLinkText: string;
    onTermsLinkClick: () => void;
    tradeConditionsLinkText?: string;
    onTradeConditionsLinkClick?: () => void;
};

export const LabelWithLinkComponent: FC<IProps> = ({
    labelText,
    termsLinkText,
    onTermsLinkClick,
    tradeConditionsLinkText = '',
    onTradeConditionsLinkClick = () => null,
}) => {
    const getLabelText = () => {
        const split = reactStringReplace(labelText, '{{terms_link}}', () => (
            <LabelText
                key={getUniqueId()}
                onClick={(e) => {
                    e.preventDefault();
                    onTermsLinkClick();
                }}
            >
                {termsLinkText}
            </LabelText>
        ));
        const addTradeConditions = reactStringReplace(split, '{{conditions_link}}', () => (
            <LabelText
                key={tradeConditionsLinkText}
                onClick={(e) => {
                    e.preventDefault();
                    onTradeConditionsLinkClick();
                }}
            >
                {tradeConditionsLinkText}
            </LabelText>
        ));
        return addTradeConditions;
    };
    return <p>{getLabelText()}</p>;
};
