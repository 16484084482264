import { ReactDOMAttributes } from '@use-gesture/react';
import { CSSProperties, ReactNode, useEffect, useRef, useState, VFC } from 'react';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { hesselViewModels } from '../../../../lib/view-models';
import { Button } from '../../button/button.component';
import { SidePanel } from '../../modals/side-panel';
import { InnerCarouselItem } from '../carousel.types';
import {
    InnerCarouselVideoContainer,
    StyledClosedButton,
    StyledIFrame,
    StyledInnerCarousel,
    StyledInnerCarouselImage,
    StyledInnerCarouselImageContainer,
    StyledInnerCarouselSlide,
    StyledInnerCarouselVideo,
    StyledReadMoreOverlay,
    StyledReadMoreOverlayContainer,
    StyledYouTubePlay,
} from './inner-carousel.styled';
import { Phyron } from '../../../../lib/view-models/vehicle';

export type InnerCarouselProps = {
    activeIndex: number;
    renderCaption?: (slide: hesselViewModels.PdpResource) => ReactNode;
    className?: string;
    dragOffset?: number;
    id: string;
    isTabbed?: boolean;
    items: InnerCarouselItem[];
    rootNodeAttrs?: () => ReactDOMAttributes;
    style?: CSSProperties;
    showImageAsCover?: boolean;
    isAboveFold?: boolean;
    useSquaredCorners?: boolean;
    blendMode?: {
        mixBlendMode: string;
        backgroundColor: string;
    };
};

export const InnerCarousel: VFC<InnerCarouselProps> = (props) => {
    const [loadedIndices, setLoadedIndices] = useState(new Set<number>());

    const videoRef = useRef<Array<HTMLVideoElement>>([]);

    useEffect(() => {
        setLoadedIndices((indices) => {
            const activeIndex = props.activeIndex;
            const nextSet = new Set([...indices].filter((x) => x < props.items.length));

            nextSet.add(activeIndex);
            nextSet.add(activeIndex + 1);
            nextSet.add(activeIndex > 0 ? activeIndex - 1 : props.items.length - 1);

            return nextSet;
        });
    }, [props, props.activeIndex, props.items]);

    const rootNodeAttrs = props.rootNodeAttrs ? props.rootNodeAttrs() : {};

    const isMobile = useMediaQuery({ target: 'tablet' });

    type YouTubeData = {
        id: string;
        baseUrl: string;
        showVideo: boolean;
    };
    const [youTubeSlide, setYouTubeSlide] = useState<YouTubeData | undefined>(undefined);

    useEffect(() => {
        if (videoRef.current.length > 0) {
            props.items
                .filter((x) => x.slide.type === 'phyron')
                .forEach((x, index) => {
                    const currentVideo = videoRef.current[index];

                    if (currentVideo) {
                        currentVideo.dataset.campaign = (x.slide as Phyron).dataCampaign;
                    }
                });
        }
    }, [props.items]);

    return (
        <>
            <StyledInnerCarousel aria-live="polite" className={props.className} id={props.id} style={props.style} {...rootNodeAttrs}>
                {props.items.map(({ id, slide }, itemIndex, { length: totalItems }) => {
                    const itemLabel = `${itemIndex + 1} af ${totalItems}`;
                    const dragOffset = props.dragOffset || 0;

                    // Video handling
                    const currentVideo = videoRef.current[itemIndex];
                    if (slide.type === 'video' && currentVideo) {
                        if (props.activeIndex === itemIndex && (slide.autoPlay ?? true)) {
                            currentVideo.play();
                        } else {
                            currentVideo.pause();
                        }
                    }

                    return (
                        <StyledInnerCarouselSlide
                            activeIndex={props.activeIndex}
                            aria-hidden={itemIndex !== props.activeIndex}
                            aria-label={itemLabel}
                            aria-roledescription="slide"
                            dragOffset={dragOffset}
                            id={id}
                            key={itemIndex}
                            role={props.isTabbed ? 'tabpanel' : 'group'}
                        >
                            {slide.type === 'image' ? (
                                slide.showMoreOverlay ? (
                                    <StyledReadMoreOverlayContainer darkmode={false}>
                                        <Button onClick={() => true}>Vis mere</Button>
                                        <StyledReadMoreOverlay>
                                            <StyledInnerCarouselImageContainer bgColor={props.blendMode?.backgroundColor}>
                                                <StyledInnerCarouselImage
                                                    src={loadedIndices.has(itemIndex) ? slide.url : undefined}
                                                    showAsCover={props.showImageAsCover}
                                                    loading={props.isAboveFold && itemIndex === 0 ? 'eager' : 'lazy'}
                                                    useSquaredCorners={props.useSquaredCorners ?? true}
                                                    mixBlendMode={props.blendMode?.mixBlendMode}
                                                />
                                            </StyledInnerCarouselImageContainer>
                                        </StyledReadMoreOverlay>
                                    </StyledReadMoreOverlayContainer>
                                ) : (
                                    <StyledInnerCarouselImageContainer bgColor={props.blendMode?.backgroundColor}>
                                        <StyledInnerCarouselImage
                                            src={loadedIndices.has(itemIndex) ? slide.url : undefined}
                                            showAsCover={props.showImageAsCover}
                                            loading={props.isAboveFold && itemIndex === 0 ? 'eager' : 'lazy'}
                                            useSquaredCorners={props.useSquaredCorners ?? true}
                                            mixBlendMode={props.blendMode?.mixBlendMode}
                                        />
                                    </StyledInnerCarouselImageContainer>
                                )
                            ) : null}
                            {slide.type === 'video' ? (
                                <StyledInnerCarouselVideo
                                    ref={(element: HTMLVideoElement) => (videoRef.current[itemIndex] = element)}
                                    playsInline={true}
                                    autoPlay={slide.autoPlay ?? true}
                                    muted={true}
                                    loop={true}
                                    src={slide.url}
                                    useSquaredCorners={props.useSquaredCorners}
                                    onClick={() => {
                                        const currentVideo = videoRef.current[itemIndex];

                                        if (!currentVideo) {
                                            return;
                                        }

                                        if (currentVideo.paused) {
                                            currentVideo.play();
                                        } else {
                                            currentVideo.pause();
                                        }
                                    }}
                                />
                            ) : null}
                            {slide.type === 'youTube' && itemIndex === props.activeIndex ? (
                                !isMobile ? (
                                    <StyledIFrame
                                        useSquaredCorners={props.useSquaredCorners}
                                        title={`youTube-id:${slide.id}`}
                                        width="100%"
                                        height="100%"
                                        src={`${slide.baseUrl}/${slide.youTubeId}`}
                                        allowFullScreen={true}
                                        style={{ border: 'none' }}
                                    />
                                ) : (
                                    <>
                                        <StyledInnerCarouselImage
                                            src={`https://img.youtube.com/vi/${slide.youTubeId}/0.jpg`}
                                            showAsCover={true}
                                            onClick={() =>
                                                setYouTubeSlide({
                                                    id: slide.youTubeId,
                                                    baseUrl: slide.baseUrl,
                                                    showVideo: true,
                                                })
                                            }
                                            style={{ cursor: 'pointer' }}
                                        />

                                        <StyledYouTubePlay
                                            src="/icons/youtube-play.png"
                                            showAsCover={true}
                                            onClick={() =>
                                                setYouTubeSlide({
                                                    id: slide.youTubeId,
                                                    baseUrl: slide.baseUrl,
                                                    showVideo: true,
                                                })
                                            }
                                        />
                                    </>
                                )
                            ) : null}

                            {slide.type === 'phyron' && (
                                <InnerCarouselVideoContainer>
                                    <StyledInnerCarouselVideo
                                        className="phyron-video"
                                        // Needs to be set after video component is rendered
                                        // data-campaign={slide.dataCampaign}
                                        data-product-key={slide.referenceNumber}
                                        data-file="1024x768"
                                        data-tracking-source="phyron"
                                        src={slide.url}
                                        poster={slide.poster}
                                        ref={(element: HTMLVideoElement) => (videoRef.current[itemIndex] = element)}
                                        playsInline={true}
                                        muted={true}
                                        loop={true}
                                        useSquaredCorners={props.useSquaredCorners}
                                        onClick={() => {
                                            const currentVideo = videoRef.current[itemIndex];

                                            if (!currentVideo) {
                                                return;
                                            }

                                            if (currentVideo.paused) {
                                                currentVideo.play();
                                            } else {
                                                currentVideo.pause();
                                            }
                                        }}
                                    />
                                </InnerCarouselVideoContainer>
                            )}
                            {props.renderCaption ? props.renderCaption(slide) : <div />}
                        </StyledInnerCarouselSlide>
                    );
                })}
            </StyledInnerCarousel>

            {youTubeSlide?.showVideo && isMobile ? (
                <SidePanel cancelAction={() => setYouTubeSlide(undefined)} isVisible={youTubeSlide?.showVideo} variant="full">
                    <StyledClosedButton label="" onClick={() => setYouTubeSlide(undefined)} />
                    <StyledIFrame
                        useSquaredCorners={props.useSquaredCorners}
                        width="100%"
                        height="100%"
                        src={`${youTubeSlide.baseUrl}/${youTubeSlide.id}`}
                        title={`youTube-id-mobile:${youTubeSlide.id}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></StyledIFrame>
                </SidePanel>
            ) : null}
        </>
    );
};
