import { FC, useEffect, useMemo, useState } from 'react';
import { StyledInputGroup, StyledInputHeader, StyledInputsWrapper } from './product-installation.styled';
import { DropdownInput, TextInput } from '../../../../forms/inputs';
import { StyledFormWrapper } from '../../customer-info/shop-customer-info.styled';
import { DatePickerDropdown } from '../../../../shared';
import { DealershipInformationPage } from '../../../../../lib/api/models/umbraco';
import { getPageByDataTypeAliasGeneric } from '../../../../../lib/api';
import { DateStyle, createTimeSlots, formatDate, getClosedDaysList } from '../../../../../utils/helpers';
import { InstallationRequest } from '../shop-delivery-step.component';
import { ShopCheckoutDisclaimer } from '../../checkout-disclaimer/checkout-disclaimer.component';

type IProps = {
    updateInstallationRequest: (ir: InstallationRequest) => void;
    offsetDays: number;
};

export const ProductInstallation: FC<IProps> = ({ updateInstallationRequest, offsetDays }) => {
    const [departments, setDepartments] = useState<Array<DealershipInformationPage>>();
    const [selectedDepartment, setSelectedDepartment] = useState<DealershipInformationPage>();
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedTimeslot, setSelectedTimeslot] = useState<{
        displayValue: string;
        value: string;
    }>();
    const [userRegNumber, setUserRegNumber] = useState('');

    useEffect(() => {
        updateInstallationRequest({
            date: selectedDate,
            department: selectedDepartment,
            timeslot: selectedTimeslot?.value,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selectedDepartment, selectedTimeslot]);

    useEffect(() => {
        const getAllDealership = async () => {
            const [dealerships, error] = await getPageByDataTypeAliasGeneric<Array<DealershipInformationPage>>('dealershipInformation');
            if (dealerships && !error) {
                const dealershipsWithCorrectDepartment = dealerships.filter(
                    (x) =>
                        x.departments.find((y) => y.departmentType === 'Værksted') &&
                        x.hovedafdelingId !== '' &&
                        x.hovedafdelingId !== null &&
                        x.hovedafdelingId !== undefined
                );
                setDepartments(dealershipsWithCorrectDepartment);
            }
        };
        getAllDealership();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const departmentsMappedToDropdown = useMemo(() => {
        if (!departments) return [];
        return departments.map((x) => {
            return {
                displayValue: x.displayName,
                value: x.hovedafdelingId,
                subLabel: `${x.address}, ${x.zipcode} ${x.city}`,
            };
        });
    }, [departments]);

    const closedDays = useMemo(() => {
        if (selectedDepartment) {
            return getClosedDaysList(selectedDepartment, 'Værksted');
        }
        return [0, 1, 2, 3, 4, 5, 6];
    }, [selectedDepartment]);

    const timeSlots = useMemo(() => {
        if (!selectedDepartment || !selectedDate) return [];
        const timeslots = createTimeSlots(selectedDepartment, selectedDate);
        return timeslots
            .filter((x) => x > new Date())
            .splice(0, timeslots.length - 4)
            .map((ts) => {
                const formattedDate = formatDate(ts, DateStyle.HH_mm);
                return {
                    displayValue: formattedDate,
                    value: formattedDate,
                };
            });
    }, [selectedDate, selectedDepartment]);

    return (
        <div>
            <ShopCheckoutDisclaimer disclaimerText="Din ordre kan som udgangspunkt afhentes samlet den dag, du har valgt montering. Hvis du ønsker at afhente nogle af varerne tidligere, er du velkommen til at kontakte os." />
            <p style={{ marginTop: '10px' }}>Dette er blot en booking forespørgsel, vi vil kontakte dig, for at bekræfte din tidsbooking</p>
            <StyledFormWrapper>
                <StyledInputGroup>
                    <StyledInputHeader>Hvor ønsker du montering?</StyledInputHeader>
                    <DropdownInput
                        canValidateInputField={false}
                        id="installation-shop"
                        isValid={false}
                        label="AFDELING"
                        onChange={(selection) => {
                            if (!departments || !selection) return null;
                            const departmentMatch = departments.find((x) => x.hovedafdelingId === selection.value);
                            setSelectedDepartment(departmentMatch);
                            setSelectedDate(undefined);
                            setSelectedTimeslot(undefined);
                        }}
                        options={departmentsMappedToDropdown}
                        placeholder="Vælg ønsket afdeling"
                        value={
                            selectedDepartment &&
                            departments &&
                            departmentsMappedToDropdown.find((x) => x.value === selectedDepartment.hovedafdelingId)
                                ? departmentsMappedToDropdown.find((x) => x.value === selectedDepartment.hovedafdelingId)
                                : undefined
                        }
                    />
                </StyledInputGroup>
                <StyledInputGroup>
                    <StyledInputHeader>Vælg ønsket afdeling, dato og tidspunkt</StyledInputHeader>
                    <StyledInputsWrapper>
                        <DatePickerDropdown
                            canValidateInputField={false}
                            id="installation-date"
                            isValid={false}
                            label="DATO"
                            onChange={() => null}
                            onDayChange={(e) => {
                                setSelectedDate(e);
                                setSelectedTimeslot(undefined);
                            }}
                            waitingDays={offsetDays}
                            placeholder="Vælg ønsket dato"
                            value={
                                selectedDate
                                    ? {
                                          displayValue: formatDate(selectedDate, DateStyle.dk_full_text),
                                          value: formatDate(selectedDate, DateStyle.dk_full_text),
                                      }
                                    : undefined
                            }
                            selectedDay={selectedDate}
                            disableDays={closedDays}
                            disabled={selectedDepartment === undefined}
                        />
                        <DropdownInput
                            canValidateInputField={false}
                            id="installation-timeslot"
                            isValid={false}
                            label="TIDSPUNKT"
                            onChange={(e) => {
                                if (e) setSelectedTimeslot(e);
                            }}
                            options={timeSlots}
                            placeholder="Vælg tidspunkt"
                            value={selectedTimeslot}
                            disabled={selectedDepartment === undefined || selectedDate === undefined}
                        />
                    </StyledInputsWrapper>
                </StyledInputGroup>
                <StyledInputGroup>
                    <StyledInputHeader>Hvad er dit reg. nr?</StyledInputHeader>
                    <TextInput
                        canValidateInputField={false}
                        id="installation-reg"
                        label="REG. NR."
                        type="text"
                        onChange={(e) => setUserRegNumber(e.target.value)}
                        isValid={false}
                        placeholder="Bilens registreringsnummer"
                        value={userRegNumber}
                    />
                </StyledInputGroup>
            </StyledFormWrapper>
        </div>
    );
};
