import { GtmTrackingProvider, propsFromSettings } from '../../gtm-tracking/components/gtm-tracking-provider.component';
import { useEffect, useMemo, VFC } from 'react';
import { umbraco } from '../../lib/api';
import { UiProvider } from '../ui';
import { ProductDetailsPage } from '../product-details/product-details-page/product-details-page.component';
import { HesselLayout } from './hessel-layout';
import { HireLeadCheckout } from '../hire/hire-lead-checkout';
import { ContentSpots } from '../spots';
import { HireContentDisplayStore } from '../../lib/state/hessel-site/display-manager';
import { PaymentPlanSidebar } from '../hire/_shared';
import { cmsUrlWithoutSiteId, formInfoFromSpot } from '../../utils/helpers';
import { FormsManagerStore } from '../../lib/state/plus-sites/forms-display-manager';
import { FormsContentHandler } from '../plus-sites-shared/content-handlers';
import { DisplayManagerStore } from '../../lib/state/display-manager';
import { ReepayStore } from '../../lib/state/reepay-form/reepay-form.store';
import { hesselViewModels } from '../../lib/view-models';
import { MiniBasketStore } from '../../lib/state/hessel-site/mini-basket';
import { ProductDetailsStore } from '../../lib/state/hessel-site/product-details';
import { EquipmentDialogManagerStore } from '../../lib/state/hessel-site/equipment-dialog-manager';
import { SizeGuideForModel } from '../../lib/api/models/umbraco';
import { ProductOwnershipStore } from '../../lib/state/hessel-site/product-ownership/product-ownership.store';
import { PlusSite } from '../plus/plus-site';
import { PageElements } from '../page-elements';
import { StyledHesselCenteredBlock, StyledMain } from '../booking/layout/booking-layout.styled';
import { HelpCenter } from '../help-center';
import FindDealershipPage from '../organization/find-dealership-page/find-dealership-page.component';
import { DealershipInformationPage } from '../organization/dealership-information-page/dealership-information-page.component';
import { ShopProductDetailsPage } from '../shop/shop-product-details-page/shop-product-details-page.component';
import { ShopProductDetails } from '../../lib/api/models/shop';
import { CartPage } from '../shop/cart/cart-page-component';
import { CartStoreContext } from '../../lib/state/shop/cart/cart.store';
import { ShopCheckoutPage } from '../shop/checkout/shop-checkout-page.component';
import { ShopCategoryPage } from '../shop/category/shop-category-page.component';
import { ServiceAgreementCalculatorModalStore } from '../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { GlobalEcommerceSettingsContext } from '../../lib/state/global-ecommerce-settings/global-ecommerce-settings.store';
import SalesVideoPage from '../sales-video/sales-video-page.component';
import { SalesVideoData } from '../../lib/api/models/hessel-api/sales-video';
import { GlobalDealershipSettingsContext } from '../../lib/state/global-dealership-settings/global-dealership-settings.store';
import { useRelewiseTracking } from '../../hooks/relewise/use-relewise-tracking';
import { SearchStoreContext } from '../../lib/state/search/search.store';
import HireEmailCustomerActionPage from '../hire-email-customer-action/hire-email-customer-action-page.component';
import SolutionsAcceptEmailedOfferPage from '../solutions-accept-emailed-offer/solutions-accept-emailed-offer.component';

export type HesselSiteProps = {
    page: umbraco.Page;
    siteSettings: umbraco.SiteSettings;
    pdpData?: {
        carFamily: Array<hesselViewModels.ProductDetails>;
        allConfigurations: hesselViewModels.CarConfiguration;
        product: hesselViewModels.ProductDetails;
        color: hesselViewModels.ProductColor;
        queryColor: hesselViewModels.ProductColor;
    };
    sizeGuides?: SizeGuideForModel[];
    carTypeAndImages?: Array<umbraco.CarTypeAndImageList>;
    shopProduct?: ShopProductDetails;
    shopProductFamily?: Array<ShopProductDetails>;
    salesVideo?: SalesVideoData | null;
};

export const HesselSite: VFC<HesselSiteProps> = ({ siteSettings, page, pdpData, shopProduct, shopProductFamily, salesVideo }) => {
    if (page.contentTypeAlias === 'linkedContentPage') page = page.contentPicker;

    // Ownership store
    const { ownershipMode, ownershipTab } = ProductOwnershipStore.useStoreState((state) => state);

    const gtmProps = useMemo(() => propsFromSettings(siteSettings), [siteSettings]);

    const formList = useMemo(() => {
        if ('sidebarContent' in page) {
            return [...page.sidebarContent.map(formInfoFromSpot)];
        }

        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const { globalPlpSettings, genericTexts, headerSettings, paymentIcons, dealershipSettings } = useMemo(() => {
        return {
            globalPlpSettings: siteSettings.marketSettings[0].productListSettings,
            genericTexts: siteSettings.marketSettings[0].genericTexts,
            headerSettings: siteSettings.marketSettings[0].hireHeaderSettings,
            paymentIcons: siteSettings.marketSettings[0].footer.paymentIcons,
            dealershipSettings: siteSettings.marketSettings[0].dealershipSettings,
        };
    }, [siteSettings.marketSettings]);

    const { trackContentView, contentTypesExcludedFromTracking } = useRelewiseTracking();

    useEffect(() => {
        if (!contentTypesExcludedFromTracking.includes(page.contentTypeAlias.toString())) {
            trackContentView(page.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page.id]);

    const loadPage = (page: umbraco.Page) => {
        switch (page.contentTypeAlias) {
            case 'hireRootPage':
            case 'contentPage':
            case 'hesselRootPage':
            case 'carSaverContentPage':
                return (
                    <ContentSpots
                        contentSpotSettings={page.filteredContentSpots ? page.filteredContentSpots : page.contentSpotsSettings}
                        context={{ pageId: page.key }}
                        globalPlpSettings={globalPlpSettings}
                    />
                );
            case 'productDetailsPage':
                return <ProductDetailsPage page={page} globalEcomSettings={globalPlpSettings} siteSettings={siteSettings} />;
            case 'checkoutPage':
                return (
                    <HireLeadCheckout
                        checkoutSteps={page.stepList}
                        helpSettings={page.helpSettings}
                        noCarData={page.noCarData ? page.noCarData[0] : undefined}
                        leasingAgreementLink={page.leasingAgreementLink ?? []}
                        seePaymentPlanLinkText={page.seePaymentPlanLinkText ?? ''}
                        seePaymentPlanLinkIcon={page.seePaymentPlanLinkIcon ?? undefined}
                        headerSettings={headerSettings}
                        miniBasketSettings={siteSettings.marketSettings[0].miniBasketSettings}
                    />
                );
            case 'bookingMainPage':
                return (
                    <StyledHesselCenteredBlock>
                        <StyledMain>
                            <PageElements page={page} siteSettings={siteSettings} />
                            <HelpCenter helpSettings={page.helpSettings} />
                        </StyledMain>
                    </StyledHesselCenteredBlock>
                );
            case 'plusSubscriptionPage':
            case 'receiptPage':
            case 'carSaverRootPage':
                return <PlusSite page={page} siteSettings={siteSettings} />;
            case 'findDealershipPage':
                return (
                    <FindDealershipPage
                        page={page}
                        emergencyPhoneNumbers={page.emergencyPhoneNumbers}
                        contentSpotSettings={page.contentSpotsSettings}
                        demoCarNotAvailableModal={page.demoCarNotAvailableModel}
                        metaMenu={page.pageMetaMenu?.[0] ?? null}
                        settings={siteSettings}
                    />
                );
            case 'dealershipInformation':
                return (
                    <DealershipInformationPage
                        page={page}
                        dealership={{
                            dealershipId: page.autolineId,
                            hovedafdelingId: page.hovedafdelingId,
                            header: page.name,
                            lat: page.latitude,
                            lng: page.longitude,
                            city: page.city,
                            zipcode: page.zipcode,
                            phone: page.phone,
                            address: page.address,
                            email: page.eMail,
                            url: page.url,
                            departments: page.departments,
                            supportedBrands: page.supportedBrands,
                            employeesPlaceholderImage: page.employeesPlaceholderImage,
                            writeUsForm: page.writeUsForm[0],
                            certificates: page.certifications,
                            letter: 'A',
                            displayName: page.displayName,
                            testDriveOpeningHours: {
                                openingHours: page.testDriveOpeningHours?.[0]?.openingHours ?? [],
                                specialDays: page.testDriveOpeningHours?.[0]?.specialDays ?? [],
                            },
                            bookWorkshopModalTexts: {
                                bookWorkshopHeader: page.bookWorkshopHeader,
                                bookWorkshopDescription: page.bookWorkshopDescription,
                                bookWorkshopModalButtonText: page.bookWorkshopModalButton,
                            },
                            dealershipImage: page.dealershipImage,
                        }}
                        siteSettings={siteSettings}
                        metaMenu={page.pageMetaMenu?.[0] ?? null}
                    />
                );
            case 'shopProductDetailsPage':
                if (shopProduct && shopProductFamily)
                    return <ShopProductDetailsPage product={shopProduct} productFamily={shopProductFamily} page={page} />;
                return null;
            case 'cartPage':
                return <CartPage paymentIcons={paymentIcons} page={page} />;
            case 'shopCheckoutPage':
                return <ShopCheckoutPage page={page} paymentIcons={paymentIcons} />;
            case 'shopCategoryPage':
                return <ShopCategoryPage page={page} globalPlpSettings={globalPlpSettings} />;
            case 'salesVideoPage':
                return (
                    salesVideo && (
                        <SalesVideoPage page={page} context={{ pageId: page.key }} globalPlpSettings={globalPlpSettings} salesVideo={salesVideo} />
                    )
                );
            case 'hireEmailCustomerActionPage':
                return (
                    <HireEmailCustomerActionPage
                        context={{ pageId: page.id }}
                        contentSpots={page.contentSpots}
                        globalPlpSettings={globalPlpSettings}
                    ></HireEmailCustomerActionPage>
                );
            case 'solutionsAcceptEmailedOfferPage':
                return <SolutionsAcceptEmailedOfferPage page={page} />;

            default:
                return <p>Invalid page content type alias: {page.contentTypeAlias}</p>;
        }
    };

    const extraBreadcrumbContext = useMemo(() => {
        if (page.contentTypeAlias === 'productDetailsPage' && pdpData) {
            return `${pdpData.product.brand ? `${pdpData.product.brand},` : ''} ${pdpData.product.variantTitle ?? ''}`;
        } else if (page.contentTypeAlias === 'shopProductDetailsPage' && shopProduct) {
            return shopProduct.name;
        }
        return undefined;
    }, [page.contentTypeAlias, pdpData, shopProduct]);

    return (
        <GtmTrackingProvider {...gtmProps}>
            <DisplayManagerStore.Provider>
                <ReepayStore.Provider>
                    <MiniBasketStore.Provider>
                        <FormsManagerStore.Provider runtimeModel={{ formList }} key={page.id}>
                            <UiProvider>
                                <FormsContentHandler context={{ pageId: page.key }} />
                                <HireContentDisplayStore.Provider>
                                    <ProductDetailsStore.Provider
                                        runtimeModel={{
                                            includedEquipmentText: genericTexts.includedEquipmentLabel,
                                            allConfigurations: pdpData?.allConfigurations,
                                            selectedProduct: pdpData?.product,
                                            selectedColor: pdpData?.color,
                                            queryColor: pdpData?.queryColor,
                                            carFamily: pdpData?.carFamily ?? [],
                                            relatedVehicles: [],
                                            ownershipMode: ownershipMode,
                                            selectedTab: ownershipTab,
                                            globalPlpSettings: globalPlpSettings,
                                        }}
                                        key={pdpData?.product?.id}
                                    >
                                        <EquipmentDialogManagerStore.Provider>
                                            <CartStoreContext.Provider
                                                runtimeModel={{
                                                    cartUrl: cmsUrlWithoutSiteId(globalPlpSettings.cartPage?.url),
                                                    checkoutUrl: cmsUrlWithoutSiteId(globalPlpSettings.checkoutPage?.url),
                                                }}
                                            >
                                                <ServiceAgreementCalculatorModalStore.Provider>
                                                    <GlobalEcommerceSettingsContext.Provider
                                                        runtimeModel={{
                                                            settings: globalPlpSettings,
                                                        }}
                                                    >
                                                        <GlobalDealershipSettingsContext.Provider
                                                            runtimeModel={{
                                                                settings: dealershipSettings,
                                                            }}
                                                        >
                                                            <SearchStoreContext.Provider>
                                                                <HesselLayout
                                                                    settings={siteSettings}
                                                                    page={page}
                                                                    extraBreadcrumbContext={extraBreadcrumbContext}
                                                                >
                                                                    {loadPage(page)}
                                                                </HesselLayout>
                                                            </SearchStoreContext.Provider>
                                                        </GlobalDealershipSettingsContext.Provider>
                                                    </GlobalEcommerceSettingsContext.Provider>
                                                </ServiceAgreementCalculatorModalStore.Provider>
                                            </CartStoreContext.Provider>
                                            <PaymentPlanSidebar numberOfNoCostMonths={3} />
                                        </EquipmentDialogManagerStore.Provider>
                                    </ProductDetailsStore.Provider>
                                </HireContentDisplayStore.Provider>
                            </UiProvider>
                        </FormsManagerStore.Provider>
                    </MiniBasketStore.Provider>
                </ReepayStore.Provider>
            </DisplayManagerStore.Provider>
        </GtmTrackingProvider>
    );
};
