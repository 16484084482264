import { FC } from 'react';
import { StyledDisclaimerWrapper } from './checkout-disclaimer.styled';
import { SvgIcon } from '../../../shared/svg-icon';

type IProps = {
    disclaimerText: string;
    className?: string;
};

export const ShopCheckoutDisclaimer: FC<IProps> = ({ disclaimerText, className }) => {
    return (
        <StyledDisclaimerWrapper className={className}>
            <SvgIcon iconName="info" />
            <p>{disclaimerText}</p>
        </StyledDisclaimerWrapper>
    );
};
