import React, { VFC } from 'react';
import { RelewiseSimilarShopProducts } from '../../relewise-similar-shop-products/relewise-similar-shop-products.component';

type IProps = {
    header: string;
    subText: string;
    entityId: number;
};

export const ShopPdpSimilarProducsRibbonMemo: VFC<IProps> = ({ header, subText, entityId }) => {
    if (!entityId) return null;

    return (
        <div style={{ margin: '12px 0' }}>
            <RelewiseSimilarShopProducts
                spot={{
                    alias: 'relewiseRelatedVehicles',
                    entityId: entityId.toString(),
                    fromDate: new Date('0001-01-01T00:00:00'),
                    toDate: new Date('0001-01-01T00:00:00'),
                    hideBlock: false,
                    scrollAnchorId: '',
                    header,
                    subText,
                }}
            />
        </div>
    );
};

export const ShopPdpSimilarProducsRibbon = React.memo(ShopPdpSimilarProducsRibbonMemo);
