import { Tracker } from '@relewise/client';
import { getRelewiseUser, relewiseApiSetup } from '../../utils/helpers/relewise.helpers';
import { useSegmentContext } from '../use-segment-context';
import { CartObject } from '../../lib/api/models/shop/cart';

type UseRelewiseReturnType = {
    trackProductView: (productId: string) => Promise<void>;
    trackContentView: (contentId: string) => Promise<void>;
    trackVehicleConversion: (vehicleEntityId: string, vehiclePrice: number) => Promise<void>;
    trackCartRequest: (cart: CartObject) => Promise<void>;
    trackOrder: (cart: CartObject) => Promise<void>;
    contentTypesExcludedFromTracking: string[];
};

export const useRelewiseTracking = (): UseRelewiseReturnType => {
    const siteId = process.env.NEXT_PUBLIC_SITE_ID;

    const segment = useSegmentContext();

    const relewiseTracker =
        siteId &&
        siteId.toString() === 'hessel' &&
        relewiseApiSetup.apiKey &&
        relewiseApiSetup.apiKey.length > 0 &&
        relewiseApiSetup.dataset &&
        relewiseApiSetup.dataset.length > 0 &&
        relewiseApiSetup.serverUrl &&
        relewiseApiSetup.serverUrl.length > 0
            ? new Tracker(relewiseApiSetup.dataset, relewiseApiSetup.apiKey, {
                  serverUrl: relewiseApiSetup.serverUrl,
              })
            : undefined;

    const trackProductView = async (productId: string) => {
        relewiseTracker?.trackProductView({
            productId,
            user: getRelewiseUser(segment),
        });
    };

    const trackContentView = async (contentId: string) => {
        relewiseTracker?.trackContentView({
            contentId,
            user: getRelewiseUser(segment),
        });
    };

    const trackVehicleConversion = async (vehicleEntityId: string, vehiclePrice: number) => {
        const orderNumber = window.crypto.randomUUID();
        relewiseTracker?.trackOrder({
            lineItems: [
                {
                    productId: vehicleEntityId,
                    lineTotal: vehiclePrice,
                    quantity: 1,
                },
            ],
            user: getRelewiseUser(segment),
            orderNumber,
            subtotal: {
                currency: 'DKK',
                amount: vehiclePrice,
            },
        });
    };

    const trackCartRequest = async (cart: CartObject) => {
        relewiseTracker?.trackCart({
            subtotal: {
                currency: 'DKK',
                amount: cart.subTotal,
            },
            user: getRelewiseUser(segment),
            lineItems: cart.lineItems.map((item) => ({
                productId: item.productEntityId,
                lineTotal: item.totalPrice,
                quantity: item.quantity,
            })),
        });
    };

    const trackOrder = async (cart: CartObject) => {
        relewiseTracker?.trackOrder({
            lineItems: cart.lineItems.map((item) => ({
                productId: item.productEntityId,
                lineTotal: item.totalPrice,
                quantity: item.quantity,
            })),
            orderNumber: cart.id,
            user: getRelewiseUser(segment),
            subtotal: {
                currency: 'DKK',
                amount: cart.subTotal,
            },
        });
    };

    const contentTypesExcludedFromTracking = [
        'productDetailsPage',
        'checkoutPage',
        'receiptPage',
        'shopProductDetailsPage',
        'salesVideoPage',
        'shopCheckoutPage',
        'cartPage',
    ];

    return {
        trackProductView,
        trackContentView,
        trackVehicleConversion,
        trackCartRequest,
        trackOrder,
        contentTypesExcludedFromTracking,
    };
};
