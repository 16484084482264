import React, { useState, VFC } from 'react';
import { OrganizationConsts } from '../../../constants';
import { DEALERSHIP_MAP_ZOOM_LEVEL } from '../../../constants/organization-consts';
import { useScrollLock } from '../../../hooks/use-scroll-lock';
import { uiTypes } from '../../../lib';
import { orgTypes } from '../../../lib/api/models/umbraco';
import { FormsSpot } from '../../../lib/api/models/umbraco/content-spot';
import { getGoogleMapsUrl } from '../../../utils/helpers/geo-location.helpers';
import { Button } from '../../shared';
import { SimpleGoogleMap } from '../simple-map/_simple-map-wrapper/simple-map-wrapper.component';
import { WriteUsFormDialog } from '../write-us-modal/write-us-form-modal.component';
import {
    StyledDealershipContactInfo,
    InformationColumn,
    Header,
    Description,
    InfoWrapper,
    IconAndTextWrapper,
    StyledMapPin,
    StyledAnchor,
    StyledPhone,
    StyledMail,
    AddressWrapper,
    StyledAddress,
    StyledMapOrImageWrapper,
    CtasWrapper,
    ButtonsWrapper,
    StyledCenteredBlock,
    StyledDealershipImage,
} from './dealership-contact-info.styled';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { OpenDialog, usePdpModals } from '../../product-details/product-details-page/product-details-page.context';

type DealershipContactInfoProps = {
    header: string;
    description: string;
    bookTimeText?: string;
    writeToUsText?: string;
    findEmployeesText?: string;
    bookTestDriveText?: string;
    writeUsForm?: FormsSpot;
    pageId: string;
    dealership: orgTypes.DealershipWithGeoInfo | null;
    allDealerships: Array<orgTypes.DealershipWithGeoInfo>;
    usedOnContactPage?: boolean;
    headerType?: uiTypes.HeaderType;
    hasDemoVehicles?: boolean;
    hasEmployees?: boolean;
    hasAtLeastOneWorkshopDepartment?: boolean;
    spotId?: string;
    renderGoogleMap: boolean;
    isPdpContext?: boolean;
};

const DealershipContactInfoMemo: VFC<DealershipContactInfoProps> = ({
    header,
    description,
    bookTimeText = 'Book tid',
    writeToUsText = 'Skriv til os',
    findEmployeesText = 'Find medarbejder',
    bookTestDriveText = 'Book prøvetur',
    writeUsForm,
    pageId,
    dealership,
    usedOnContactPage = true,
    headerType,
    hasDemoVehicles,
    hasEmployees,
    hasAtLeastOneWorkshopDepartment,
    spotId,
    renderGoogleMap,
    isPdpContext = false,
}) => {
    const [showWriteUsModal, setShowWriteUsModal] = useState(false);
    const { applyScrollBars, hideScrollBars } = useScrollLock();
    const { openDialog } = usePdpModals();

    if (!dealership) {
        return null;
    }

    const { address, zipcode, city, phone, email, lat, lng, dealershipImage, displayName } = dealership;

    return (
        <>
            <StyledCenteredBlock>
                <StyledDealershipContactInfo id={spotId ?? `dealership-contact-info-${dealership.dealershipId}`}>
                    <InformationColumn>
                        <Header as={headerType}>{header}</Header>

                        <Description>{description}</Description>

                        <InfoWrapper>
                            <IconAndTextWrapper>
                                <StyledPhone />
                                <StyledAnchor href={`tel:${phone}`}>{phone}</StyledAnchor>
                            </IconAndTextWrapper>

                            <IconAndTextWrapper>
                                <StyledMail />
                                <StyledAnchor href={`mailto:${email}`}>{email}</StyledAnchor>
                            </IconAndTextWrapper>

                            <IconAndTextWrapper>
                                <StyledMapPin />
                                <AddressWrapper>
                                    <StyledAddress>
                                        {address}, {zipcode} {city}
                                    </StyledAddress>
                                    <StyledAnchor
                                        onClick={(e) => e.stopPropagation()}
                                        href={getGoogleMapsUrl(address, zipcode).toString()}
                                        target={'_blank'}
                                    >
                                        Rutevejledning
                                    </StyledAnchor>
                                </AddressWrapper>
                            </IconAndTextWrapper>
                            <CtasWrapper>
                                <ButtonsWrapper>
                                    {usedOnContactPage && hasAtLeastOneWorkshopDepartment ? (
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                const salesAndServiceSpot = document.getElementById(OrganizationConsts.SalesAndServiceSpot);
                                                if (salesAndServiceSpot) {
                                                    salesAndServiceSpot.scrollIntoView({
                                                        behavior: 'smooth',
                                                    });
                                                }
                                            }}
                                        >
                                            {bookTimeText}
                                        </Button>
                                    ) : null}
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            if (isPdpContext) {
                                                openDialog({
                                                    dialog: OpenDialog.ContactHessel,
                                                    sidebarOpened: 'Kontakt Hessel',
                                                });
                                            } else {
                                                hideScrollBars();
                                                setShowWriteUsModal(true);
                                            }
                                        }}
                                    >
                                        {writeToUsText}
                                    </Button>
                                </ButtonsWrapper>
                                <ButtonsWrapper>
                                    {usedOnContactPage && hasEmployees ? (
                                        <Button
                                            variant="light"
                                            onClick={() => {
                                                const employeesSpot = document.getElementById(OrganizationConsts.FindEmployeesSpot);
                                                if (employeesSpot) {
                                                    employeesSpot.scrollIntoView({
                                                        behavior: 'smooth',
                                                    });
                                                }
                                            }}
                                        >
                                            {findEmployeesText}
                                        </Button>
                                    ) : null}
                                    {usedOnContactPage && hasDemoVehicles ? (
                                        <Button
                                            variant="light"
                                            onClick={() => {
                                                const demoCarsRibbonSpot = document.getElementById(OrganizationConsts.DemoCarsRibbonSpot);
                                                if (demoCarsRibbonSpot) {
                                                    demoCarsRibbonSpot.scrollIntoView({
                                                        behavior: 'smooth',
                                                    });
                                                }
                                            }}
                                        >
                                            {bookTestDriveText}
                                        </Button>
                                    ) : null}
                                </ButtonsWrapper>
                            </CtasWrapper>
                        </InfoWrapper>
                    </InformationColumn>
                    <StyledMapOrImageWrapper>
                        {renderGoogleMap || !dealershipImage || dealershipImage.src.length === 0 ? (
                            <SimpleGoogleMap lat={lat} lng={lng} zoom={DEALERSHIP_MAP_ZOOM_LEVEL} />
                        ) : (
                            <StyledDealershipImage
                                src={
                                    dealershipImage && dealershipImage.src.length > 0
                                        ? `${MEDIA_URL}/${dealershipImage.src}`
                                        : '/fallback-images/dealership.jpg'
                                }
                                alt={displayName}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(getGoogleMapsUrl(address, zipcode).toString(), '_blank');
                                }}
                            />
                        )}
                    </StyledMapOrImageWrapper>
                </StyledDealershipContactInfo>
            </StyledCenteredBlock>
            {writeUsForm ? (
                <WriteUsFormDialog
                    isVisible={showWriteUsModal}
                    form={writeUsForm}
                    formHeader={writeUsForm.formHeader}
                    formSubText={writeUsForm.formSubtext}
                    onClose={() => {
                        applyScrollBars();
                        setShowWriteUsModal(false);
                    }}
                    pageId={pageId}
                    dealershipId={dealership.dealershipId}
                />
            ) : null}
        </>
    );
};

export const DealershipContactInfo = React.memo(DealershipContactInfoMemo);
