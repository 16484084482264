import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledSpotWrapper = styled.div`
    background-color: #f7f7f8;
    padding: 30px 0;

    @media ${device.laptop} {
        padding: 46px 0;
    }
`;
