import Link from 'next/link';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import reactStringReplace from 'react-string-replace';
import { useShopProductlist } from '../../../hooks/productlist/use-shop-productlist';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { useOnScreen } from '../../../hooks/use-on-screen';
import { GlobalProductListSettings } from '../../../lib/api/models/umbraco';
import { ShopProductList } from '../../../lib/api/models/umbraco/content-spot';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { toggleChatBot } from '../../../utils/helpers';
import { HorizontalDragSlider } from '../../shared/horizontal-drag-slider/horizontal-drag-slider.component';
import { LoadMore } from '../../shared/load-more';
import { SvgIcon } from '../../shared/svg-icon';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { ShopProductCard } from '../../shop/shop-product-card/shop-product-card.component';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { ProductListFilterTiles } from '../../vehicle/vehicle-product-list/product-list-filter-tiles/product-list-filter-tiles';
import { VehicleProductListSortingDropdown } from '../../vehicle/vehicle-product-list/vehicle-product-list-sorting-dropdown/vehicle-product-list-sorting-dropdown.component';
import { LoadingBlocker } from '../../vehicle/vehicle-product-list/vehicle-product-list.styled';
import { ProductListFilterMobile } from './product-list-filter-mobile/product-list-filter-mobile.component';
import { ProductListFilterSection } from './product-list-filter-section/product-list-filter-section.component';
import {
    StyledDesktopOnlyWrapper,
    StyledDeviceOnlyWrapper,
    StyledFilterTilesWrapper,
    StyledOtherCategoriesWrapper,
    StyledOtherCategory,
    StyledPreviousCategoryLink,
    StyledProductCardWrapper,
    StyledProductCount,
    StyledProductListFacetsAndProductsWrapper,
    StyledResultsTextAndSortingWrapper,
    StyledSpotWrapper,
} from './shop-product-list.styled';
import { ShopProductDetails } from '../../../lib/api/models/shop';
import { MarketingProductCard } from '../../../lib/view-models/vehicle';
import { VehicleProductListMarketingCard } from '../../vehicle/vehicle-product-list/vehicle-product-list-marketingcard';

type IProps = {
    spot: ShopProductList;
    pageId: string;
    productlistSettings: GlobalProductListSettings;
};

type ShopCardWithType = {
    card: ShopProductDetails | MarketingProductCard;
    type: string;
};

export const ShopProductListSpot: FC<IProps> = ({ spot, pageId, productlistSettings }) => {
    const {
        products,
        facets,
        filterUpdate,
        total,
        loadMore,
        filterTiles,
        resetFilters,
        isLoading,
        selectedSorting,
        sortOptions,
        updateSorting,
        activeFiltersCount,
    } = useShopProductlist({
        categoryId: spot.categoryId,
        ssrState: {
            facets: spot.ssrProductListData?.facets ?? [],
            filters: spot.ssrProductListData?.filters ?? [],
            products: spot.ssrProductListData?.products ?? [],
            total: spot.ssrProductListData?.total ?? 0,
            selectedSorting: spot.ssrProductListData?.selectedSorting ?? '',
            sortOptions: spot.ssrProductListData?.sortOptions ?? [],
            filterConfigId: spot.shopFilterConfiguration?.key ?? '',
        },
        globalProductlistSettings: productlistSettings,
    });
    const resultsText = useMemo(() => {
        return reactStringReplace(productlistSettings.resultsText, '{{amount}}', () => (
            <strong key="1" style={{ color: '#0B0B0B' }}>
                {total}
            </strong>
        ));
    }, [productlistSettings.resultsText, total]);

    const visibilityChecker = useRef<HTMLDivElement>(null);
    const isProductlistInViewport = useOnScreen(visibilityChecker);
    const isDevice = useMediaQuery({ target: 'smallDesktop' });

    toggleChatBot(isDevice && isProductlistInViewport);

    const [categoriesSliderLoaded, setCategoriesSliderLoaded] = useState(false);
    const [sliderPerView, setSlidesPerView] = useState<'auto' | number>('auto');

    const sortedCategories = useMemo(() => {
        if (!spot.categories) return [];
        const currentPage = spot.categories.find((x) => x.key === pageId);
        if (!currentPage) return spot.categories;
        return [currentPage, ...spot.categories.filter((x) => x !== currentPage)];
    }, [spot.categories, pageId]);

    const onWindowResize = () => {
        if (!spot.categories || spot.categories.length === 0 || typeof window === 'undefined') {
            setSlidesPerView('auto');
            return;
        }
        const categoriesFitInScreen = (window.innerWidth - 40) / 100;
        const categoriesRoundedDown = Math.floor(categoriesFitInScreen);
        if (categoriesFitInScreen >= spot.categories.length) {
            setSlidesPerView('auto');
            return;
        }
        setSlidesPerView(categoriesRoundedDown + 0.5);
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    });

    const allProductCards = useMemo(() => {
        const allCards: Array<ShopCardWithType> = [
            ...products.map((x) => {
                return {
                    card: x,
                    type: 'Product',
                };
            }),
        ];
        const marketingProductCards = spot.shopFilterConfiguration?.marketingProductCards.filter(
            (x) => x.index <= productlistSettings.shopPaginationSize
        );
        if (marketingProductCards && marketingProductCards.length > 0) {
            for (const card of marketingProductCards) {
                allCards.splice(card.index - 1, 0, {
                    card,
                    type: 'Marketing',
                });
            }
        }
        return allCards;
    }, [productlistSettings.shopPaginationSize, products, spot.shopFilterConfiguration?.marketingProductCards]);

    return (
        <StyledSpotWrapper ref={visibilityChecker} style={{ filter: isLoading && !isDevice ? 'blur(2px)' : 'none' }} id={spot.scrollAnchorId}>
            {isLoading ? <LoadingBlocker /> : null}
            <CenteredBlock>
                <StyledProductListFacetsAndProductsWrapper>
                    <ProductListFilterSection facets={facets} updateFilterNotifications={(updates) => filterUpdate(updates)} />
                    <div>
                        {spot.backLink ? (
                            <Link href={spot.backLink.url} passHref>
                                <StyledPreviousCategoryLink>
                                    <SvgIcon iconName="chevron/left" />
                                    <span>Gå til {spot.backLink.name}</span>
                                </StyledPreviousCategoryLink>
                            </Link>
                        ) : null}
                        <div>
                            <SpotHeader headerType={spot.headerType} headerSize={spot.headerSize}>
                                {spot.header && spot.header.length > 0 ? spot.header : spot.categoryId}
                            </SpotHeader>
                        </div>
                        {spot.categories && spot.categories.length > 0 ? (
                            <StyledOtherCategoriesWrapper style={{ opacity: categoriesSliderLoaded ? '1' : '0' }}>
                                <StyledDeviceOnlyWrapper>
                                    <HorizontalDragSlider onLoad={() => setCategoriesSliderLoaded(true)} perView={sliderPerView}>
                                        {sortedCategories.map((x) => {
                                            return (
                                                <div key={`${pageId}-${x.id}`} className="keen-slider__slide">
                                                    <Link href={x.url} passHref>
                                                        <StyledOtherCategory active={x.key === pageId}>
                                                            <span>
                                                                <img src={`${MEDIA_URL}/${x.categoryImage?.src}`} alt={x.name} />
                                                            </span>
                                                            <span>{x.name}</span>
                                                        </StyledOtherCategory>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </HorizontalDragSlider>
                                </StyledDeviceOnlyWrapper>
                                <StyledDesktopOnlyWrapper>
                                    {sortedCategories.map((x) => {
                                        return (
                                            <Link href={x.url} passHref key={`${pageId}-${x.id}`}>
                                                <StyledOtherCategory active={x.key === pageId}>
                                                    <span>
                                                        <img src={`${MEDIA_URL}/${x.categoryImage?.src}`} alt={x.name} />
                                                    </span>
                                                    <span>{x.name}</span>
                                                </StyledOtherCategory>
                                            </Link>
                                        );
                                    })}
                                </StyledDesktopOnlyWrapper>
                            </StyledOtherCategoriesWrapper>
                        ) : null}
                        <StyledResultsTextAndSortingWrapper spacing={spot.categories && spot.categories.length > 0 ? 30 : 20}>
                            <StyledProductCount>{resultsText}</StyledProductCount>
                            <VehicleProductListSortingDropdown
                                anyResults={total > 0}
                                onChangeSorting={(e) => updateSorting(e)}
                                options={sortOptions}
                                selected={selectedSorting}
                            ></VehicleProductListSortingDropdown>
                        </StyledResultsTextAndSortingWrapper>
                        <StyledFilterTilesWrapper>
                            <ProductListFilterTiles
                                filterTiles={filterTiles}
                                resetFilters={() => resetFilters()}
                                filterUpdate={(filterUpdateNotification) => filterUpdate(filterUpdateNotification)}
                            />
                        </StyledFilterTilesWrapper>
                        <StyledProductCardWrapper>
                            {allProductCards.map((x) => {
                                if (x.type === 'Product') {
                                    const card = x.card as ShopProductDetails;
                                    return (
                                        <Link href={`${card.url}`} key={card.id}>
                                            <a href={`${card.url}`} style={{ textDecoration: 'none' }}>
                                                <ShopProductCard product={card} />
                                            </a>
                                        </Link>
                                    );
                                } else {
                                    const card = x.card as MarketingProductCard;
                                    return (
                                        <VehicleProductListMarketingCard
                                            key={`marketing-${card.index}`}
                                            backgroundImage={card.backgroundImage}
                                            header={card.header}
                                            description={card.description}
                                            cta={card.cTA ? card.cTA[0] : undefined}
                                            backgroundColor={card.backgroundColor}
                                            textColor={card.textColor}
                                            isAboveFold={false}
                                        />
                                    );
                                }
                            })}
                        </StyledProductCardWrapper>
                        <LoadMore
                            onClick={() => {
                                loadMore(products.length, productlistSettings.shopPaginationSize);
                            }}
                            total={total}
                            take={productlistSettings.shopPaginationSize}
                            current={products.length}
                            loadMoreText={productlistSettings.shopLoadMoreText}
                        />
                    </div>
                </StyledProductListFacetsAndProductsWrapper>
                <ProductListFilterMobile
                    facets={facets}
                    filterUpdate={(updates) => filterUpdate(updates)}
                    isProductlistInViewport={isProductlistInViewport}
                    resetFilters={() => resetFilters()}
                    sortOptions={sortOptions}
                    selectedSorting={selectedSorting}
                    updateSorting={(sorting) => updateSorting(sorting)}
                    activeFiltersCount={activeFiltersCount}
                />
            </CenteredBlock>
        </StyledSpotWrapper>
    );
};
