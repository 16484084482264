import { FC, useEffect, useMemo, useState } from 'react';
import { getPageByDataTypeAliasGeneric } from '../../../lib/api';
import { DealershipInformationPage } from '../../../lib/api/models/umbraco';
import { EmployeeModule as EmployeeModuleSpotType, GetEmployeeByIds, GetEmployeeByQuery } from '../../../lib/api/models/umbraco/content-spot';
import { DealershipEmployee } from '../../../lib/api/models/umbraco/organization.types';
import { getEmployeesByIds, getEmployeesByQuery } from '../../../lib/api/organization/org-api';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { isNullOrEmpty } from '../../../utils/helpers';
import { EmployeeCard } from '../../employee/employee-card/employee-card.component';
import { CtaHandler } from '../../plus-sites-shared/content-handlers';
import { ToggleUi } from '../../shared/toggle-ui';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { VehicleProductCardCarousel } from '../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import { StyledCtaWrapper, StyledEmployeeGrid, StyledHeader, StyledSpotWrapper } from './employee-module.styled';

type IProps = {
    spot: EmployeeModuleSpotType;
};

export const EmployeeModule: FC<IProps> = ({ spot }) => {
    const [employees, setEmployees] = useState<Array<DealershipEmployee>>([]);
    const [dealerships, setDealerships] = useState<Array<DealershipInformationPage>>([]);
    const [selectedDepartment, setSelectedDepartment] = useState('Alle');

    useEffect(() => {
        if (spot.queryType[0].alias === 'getEmployeeByQuery') {
            const safelyCastQueryType = spot.queryType[0] as GetEmployeeByQuery;
            const convertedVehicleTypes = () => {
                if (safelyCastQueryType.vehicleTypes.length === 0) return [];
                return [
                    ...safelyCastQueryType.vehicleTypes.map((x) => {
                        switch (x) {
                            case 'Vans':
                                return 'Varebil';
                            case 'Trucks':
                                return 'Lastbil';
                            case 'Bus':
                                return 'Bus';
                            case 'Cars':
                                return 'Personbil';
                            default:
                                return 'unknown_vehicle_type';
                        }
                    }),
                ];
            };

            const getEmployeesAsync = async () => {
                const workDepartments = safelyCastQueryType.departments.reduce((prev, curr) => [...prev, curr.departmentKey], new Array<string>());
                const [result, error] = await getEmployeesByQuery({
                    departmentIds: [...safelyCastQueryType.dealerships.map((x) => Number(x.hovedafdelingId))],
                    workDepartments: workDepartments,
                    brandNames: safelyCastQueryType.brands,
                    vehicleTypes: convertedVehicleTypes(),
                });
                if (result && !error) {
                    setEmployees(result);
                }
            };
            getEmployeesAsync();
        } else {
            const safelyCastQueryType = spot.queryType[0] as GetEmployeeByIds;
            const getEmployeesAsync = async () => {
                const [result, error] = await getEmployeesByIds(safelyCastQueryType.userIds);
                if (result && !error) {
                    setEmployees(result);
                }
            };
            getEmployeesAsync();
        }
        const getDealershipAsync = async () => {
            const [result, error] = await getPageByDataTypeAliasGeneric<Array<DealershipInformationPage>>('dealershipInformation');
            if (result && !error) setDealerships(result);
        };
        getDealershipAsync();
    }, [spot]);

    const departmentsInToggle = useMemo(() => {
        if (spot.queryType[0].alias === 'getEmployeeByQuery') {
            const safelyCastQueryType = spot.queryType[0] as GetEmployeeByQuery;
            if (safelyCastQueryType.departments.length > 1)
                return [
                    {
                        label: 'Alle',
                        value: 'Alle',
                    },
                    ...safelyCastQueryType.departments.map((x) => {
                        return {
                            label: x.departmentLabel,
                            value: x.departmentKey,
                        };
                    }),
                ];
        }
        return [];
    }, [spot.queryType]);

    const fallbackEmployeeImage = useMemo(() => {
        const dealershipWithFallbackImage = dealerships.find((x) => x.employeesPlaceholderImage);
        return dealershipWithFallbackImage ? dealershipWithFallbackImage.employeesPlaceholderImage.src : '';
    }, [dealerships]);

    const employeesFilteredAndSorted = useMemo(() => {
        const filtered = employees.filter((x) => selectedDepartment === 'Alle' || x.workDepartment === selectedDepartment);
        if (selectedDepartment === 'Alle') {
            const sorted = filtered.sort((a, b) => {
                const aWebSorting = a.webSortings?.find((x) => x.departmentId === a.departmentId);
                const bWebSorting = b.webSortings?.find((x) => x.departmentId === b.departmentId);
                if ((aWebSorting?.departmentSort ?? 9999) === (bWebSorting?.departmentSort ?? 9999)) return 0;
                if ((aWebSorting?.departmentSort ?? 9999) > (bWebSorting?.departmentSort ?? 9999)) return 1;
                return -1;
            });
            return sorted;
        } else {
            const sorted = filtered.sort((a, b) => {
                const aWebSorting = a.webSortings?.find((x) => x.departmentId === a.departmentId && x.workDepartment === selectedDepartment);
                const bWebSorting = b.webSortings?.find((x) => x.departmentId === b.departmentId && x.workDepartment === selectedDepartment);
                if ((aWebSorting?.internalDepartmentSort ?? 9999) === (bWebSorting?.internalDepartmentSort ?? 9999)) return 0;
                if ((aWebSorting?.internalDepartmentSort ?? 9999) > (bWebSorting?.internalDepartmentSort ?? 9999)) return 1;
                return -1;
            });
            return sorted;
        }
    }, [employees, selectedDepartment]);

    const employeeCards = useMemo(() => {
        if (employees.length === 0 || dealerships.length === 0) return [];

        const getDealershipNamesForEmployee = (emp: DealershipEmployee) => {
            const dealershipNames = [];
            const primaryDealership = dealerships.find((x) => Number(x.hovedafdelingId) === emp.departmentId);
            if (primaryDealership) dealershipNames.push(primaryDealership.dealershipLabel);
            for (const dealership of emp.webSortings.filter((x) => x.departmentId !== emp.departmentId)) {
                const match = dealerships.find((d) => Number(d.hovedafdelingId) === dealership.departmentId);
                if (match) dealershipNames.push(match.dealershipLabel);
            }
            return [...new Set(dealershipNames)];
        };

        return employeesFilteredAndSorted.map((emp, index) => {
            return (
                <EmployeeCard
                    key={`employees-${emp.employeeId}:${index}`}
                    name={emp.name}
                    imageUrl={emp.webPicture ?? `${MEDIA_URL}${fallbackEmployeeImage}`}
                    brands={emp.brands}
                    email={emp.email}
                    phone1={emp.phone1}
                    phone2={emp.phone2}
                    title={emp.title}
                    showMobileNumber={emp.showMobileNumber}
                    dealershipNames={getDealershipNamesForEmployee(emp)}
                    className="keen-slider__slide"
                />
            );
        });
    }, [dealerships, employees.length, employeesFilteredAndSorted, fallbackEmployeeImage]);

    return (
        <StyledSpotWrapper bgColor={`#${spot.backgroundColor.length === 0 ? 'f7f7f8' : spot.backgroundColor}`} id={spot.scrollAnchorId}>
            <CenteredBlock>
                {isNullOrEmpty(spot.headerSize) ? (
                    <StyledHeader
                        as={!isNullOrEmpty(spot.headerType) ? spot.headerType : 'p'}
                        style={{ color: spot.textColor.length > 0 ? `#${spot.textColor}` : '#0b0b0b' }}
                    >
                        {spot.header}
                    </StyledHeader>
                ) : (
                    <SpotHeader
                        headerType={spot.headerType}
                        headerSize={spot.headerSize}
                        style={{ color: spot.textColor.length > 0 ? `#${spot.textColor}` : '#0b0b0b' }}
                    >
                        {spot.header}
                    </SpotHeader>
                )}
                {departmentsInToggle.length > 1 ? (
                    <div style={{ marginTop: '20px' }}>
                        <ToggleUi options={departmentsInToggle} selectedOption={selectedDepartment} onChange={(e) => setSelectedDepartment(e)} />
                    </div>
                ) : null}
            </CenteredBlock>
            {spot.showAsRibbon && employeeCards.length > 0 ? (
                <VehicleProductCardCarousel
                    header={''}
                    text={''}
                    key={selectedDepartment}
                    controlsColor={spot.textColor && spot.textColor.length > 0 ? `#${spot.textColor}` : undefined}
                >
                    {employeeCards}
                </VehicleProductCardCarousel>
            ) : (
                <CenteredBlock>
                    <StyledEmployeeGrid>{employeeCards}</StyledEmployeeGrid>
                </CenteredBlock>
            )}
            {spot.cTAs.length > 0 ? (
                <CenteredBlock>
                    <StyledCtaWrapper>
                        <CtaHandler ctas={spot.cTAs} />
                    </StyledCtaWrapper>
                </CenteredBlock>
            ) : null}
        </StyledSpotWrapper>
    );
};
